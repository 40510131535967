import { Account, IConfiguration, isGlobal, isNordic, isTmx, isUS } from '../models';
import { ImplementationType, MarketRegion, Permissions } from '../models/enums/enums';

import { selector } from 'recoil';
import { IWidgetCustomization } from '../models/customization';
import { accountState } from './account-state';
import { configurationState } from './configuration';

export const customizationState = selector({
  key: 'customizationStateKey',
  get: ({ get }) => {
    const configuration = get(configurationState);
    const account = get(accountState);
    let allowAnonymous = false;
    if (window && window.location) {
      allowAnonymous = window.location.pathname.includes('/share');
    }
    if (!configuration) {
      return undefined;
    }
    if (!account && !allowAnonymous) {
      return undefined;
    }
    return customization(configuration, account);
  },
});

const customization = (configuration: IConfiguration, account: Account | undefined) => {
  const viewPowerhousePro = account?.securityModel?.hasPermission(Permissions.VIEW_POWERHOUSE_PRO_COMMON) as boolean;
  const workWithPowerhouseProCore = account?.securityModel?.hasPermission(
    Permissions.WORK_WITH_POWERHOUSE_PRO_CORE,
  ) as boolean;
  let showIncomeStrategies = true;
  if (configuration.additionalData?.showIncomeStrategies !== undefined) {
    showIncomeStrategies = configuration.additionalData.showIncomeStrategies === 'true';
  }
  let hideIV = configuration.hideIvAndLiquidityRanks;
  if (configuration.additionalData?.hideIvAndLiquidityRanks !== undefined) {
    hideIV = configuration.additionalData.hideIvAndLiquidityRanks === 'true';
  }
  let hideRiskAndInvestmentCalculator = false;
  if (configuration.additionalData?.hideRiskAndInvestmentCalculator !== undefined) {
    hideRiskAndInvestmentCalculator = configuration.additionalData.hideRiskAndInvestmentCalculator === 'true';
  }
  let showLiquidityReport = false;
  if (configuration.additionalData?.showLiquidityReport !== undefined) {
    showLiquidityReport = configuration.additionalData.showLiquidityReport === 'true';
  }
  let showCoveredCallsReport = configuration.showCoveredCalls;
  if (configuration.additionalData?.showCoveredCalls !== undefined) {
    showCoveredCallsReport = configuration.additionalData.showCoveredCalls === 'true';
  }
  let showCreditSpreadReport = false;
  if (configuration.additionalData?.showCreditSpread !== undefined) {
    showCreditSpreadReport = configuration.additionalData.showCreditSpread === 'true';
  }
  let showEarningsCalendarReport = false;
  if (configuration.additionalData?.showEarningsCalendar !== undefined) {
    showEarningsCalendarReport = configuration.additionalData.showEarningsCalendar === 'true';
  }
  let showGuide = configuration.showGuide;
  if (configuration.additionalData?.showGuide !== undefined) {
    showGuide = configuration.additionalData.showGuide === 'true';
  }
  let showFooterOptions = true;
  if (configuration.additionalData?.showFooter !== undefined) {
    showFooterOptions = configuration.additionalData.showFooter === 'true';
  }

  let showDisclaimer = configuration.showDisclaimer;
  if (configuration.additionalData?.showDisclaimer !== undefined) {
    showDisclaimer = configuration.additionalData.showDisclaimer === 'true';
  }

  let showLocalizationOptions = !configuration.hideLocalizationOptions;
  if (configuration.additionalData?.hideLocalizationOptions !== undefined) {
    showLocalizationOptions = !(configuration.additionalData.hideLocalizationOptions === 'true');
  }

  let enableCiqPreferences = true;
  let maximumLegs = 4;
  let tradeLabel = 'app.php.common.buttons.trade';
  let tradeTicketTriggerPoint = 'strategy';
  let showTradeInstructions = true;
  let allowStockPlusOptionforCASymbol = true;
  let singleLegTradesOnly = false;
  let tradeTicketLabel = 'buyOrSell';
  let showProvidedByLabel = false;
  if (configuration.additionalData) {
    const additionalData = configuration.additionalData;
    /**
     * If `enableCiqPreferences` not present then `true`.
     * If `enableCiqPreferences` is present and value is `"true"` then `true`.
     * If `enableCiqPreferences` is present and value is `"false"` then `false`.
     * If `enableCiqPreferences` is present and value is some random then `true`.
     */
    enableCiqPreferences = !(additionalData.enableCiqPreferences === 'false');
    maximumLegs = additionalData.maximumLegs ? Number(additionalData.maximumLegs) : 4;
    tradeLabel = additionalData.tradeLabel || 'app.php.common.buttons.trade';
    tradeTicketTriggerPoint = additionalData.tradeTicketTriggerPoint || 'strategy';
    showTradeInstructions = !(additionalData.showTradeInstructions === 'false');
    allowStockPlusOptionforCASymbol = !(additionalData.allowStockPlusOptionforCASymbol === 'false');
    singleLegTradesOnly = additionalData.singleLegTradesOnly === 'true';
    tradeTicketLabel = additionalData.tradeTicketLabel || 'buyOrSell';
    showProvidedByLabel = additionalData.showProvidedByLabel === 'true';
  }

  const isMerrill =
    configuration.isEmbeddingPlatform && configuration.implementation === ImplementationType.MERRILL.toString();
  const isNasdaq =
    configuration.isEmbeddingPlatform && configuration.implementation === ImplementationType.NASDAQ.toString();

  let quotebarName = configuration.isEmbeddingPlatform ? 'variant2' : 'standard';
  if (isNasdaq) {
    quotebarName = 'variant1';
  }
  if (isGlobal()) {
    quotebarName = 'variant3';
  }

  let showQuotebarInput = true;
  if (configuration.implementation === ImplementationType.MERRILL.toString()) {
    showQuotebarInput = false;
  }

  let showSubscription = true;
  if (isNordic() || isGlobal()) {
    showSubscription = false;
  }

  return {
    isEmbeddingPlatform: configuration.isEmbeddingPlatform,
    implementation: configuration.implementation,
    isMerrill: isMerrill,
    isNasdaq: isNasdaq,
    isMinimalistic: true,
    showHeader: configuration.showHeader,
    showMarketToneInHeader: viewPowerhousePro && configuration.showMarketToneInHeader,
    showHeaderMenus: workWithPowerhouseProCore,
    showUserMenu: !configuration.isEmbeddingPlatform,
    showDisclaimer: showDisclaimer,
    showLiquidity: !configuration.hideIvAndLiquidityRanks,
    showOptionsPlayScore: !isMerrill,
    showTradeTicket: !isMerrill,
    showSingleTrade: !isMerrill,
    showLocalizationOptions: showLocalizationOptions,
    showIV: !hideIV,
    showRiskAndInvestmentCalculator: !hideRiskAndInvestmentCalculator,
    showIncomeStrategies: showIncomeStrategies,
    showNeutralStrategy: true,
    showProfileSettings: !configuration.isEmbeddingPlatform,
    enableFlipWidget: true, //newly added
    showStratgiesInformation: isMerrill, //newly added
    showFooter: isMerrill, //newly added
    quotebarName: quotebarName,
    showQuotebarInput: showQuotebarInput, //a.k.a in legacy as `disableSymbolInput`
    maximumLegs: maximumLegs, // TODO: update for merrill isMerrill ? 2 : 4,
    showLiquidityReport: configuration.showLiquidityReport || showLiquidityReport,
    showCoveredCalls: showCoveredCallsReport,
    showCreditSpread: configuration.showCreditSpread || showCreditSpreadReport,
    showEarningsCalendar: configuration.showEarningsCalendar || showEarningsCalendarReport,
    showGuide: configuration.showGuide || showGuide,
    showFooterOptions: showFooterOptions,
    showShortPuts: configuration.showShortPuts,
    showTechnicalReports:
      ((configuration.isSSO && configuration.isEmbeddingPlatform) ||
        !(configuration.isSSO || configuration.isEmbeddingPlatform)) &&
      isUS(),
    showSubscription: showSubscription,
    showCurrencyOrExchangeFlags: !isUS(),
    enableCiqPreferences: enableCiqPreferences,
    tradeLabel: tradeLabel,
    tradeTicketTriggerPoint: tradeTicketTriggerPoint,
    showTradeInstructions: showTradeInstructions,
    allowStockPlusOptionforCASymbol: allowStockPlusOptionforCASymbol,
    singleLegTradesOnly: singleLegTradesOnly,
    tradeTicketLabel: tradeTicketLabel,
    showProvidedByLabel: showProvidedByLabel,
    showWhatPanel: configuration.showWhatPanel,
  } as IWidgetCustomization;
};
