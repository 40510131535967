import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Grid, IconButton, Link, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import { IData, SentimentType, StrategyHelpers } from '@op/shared/src/models';
import { EmbedderEventType, EventType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import formatting from '@op/shared/src/models/how/formatting';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  bestProfitReturnCombinationIdState,
  customizationState,
  flippedState,
  howDataState,
  strategyModifyState,
  subViewState,
  tradeSimulatorOperationTypeState,
  tradeTicketCombinationState,
  tradingStrategiesSentimentAtom,
  tradingStrategyAtomFamilyState,
  tradingStrategySelectedStrategyState,
} from '@op/shared/src/states';
import React, { Suspense } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  CombinationHeadingWidget,
  PlChartWidget,
  ProfitLossWidget,
  RedGreenWidget,
  StrategyCardWidget,
  TradeOptionsPlayScoreWidget,
} from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { StrategyDataWidget } from '../single-trade/strategy-data-widget';
import { HowSkeletonWidget } from '../how/how-skeleton-widget';
import { GuideItem } from '../side-menu';

export interface ITradingStrategyWidgetProps extends IData<number> {}

export const TradingStrategyWidget: React.FC<ITradingStrategyWidgetProps> = ({ data }: ITradingStrategyWidgetProps) => {
  const id = data;
  const [combination, setCombination] = useRecoilState(tradingStrategyAtomFamilyState(id.toString()));
  const [selectedCombinationId, setTradingStrategySelectedCombinationId] = useRecoilState(
    tradingStrategySelectedStrategyState,
  );
  const howData = useRecoilValue(howDataState);
  const [tradeSimulatorOperation, setTradeSimulatorOperation] = useRecoilState(tradeSimulatorOperationTypeState);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const setTradeTicket = useSetRecoilState(tradeTicketCombinationState);
  const isFlipped = useRecoilValue(flippedState);
  const setSubView = useSetRecoilState(subViewState);
  const tradingStrategiesSentiment = useRecoilValue(tradingStrategiesSentimentAtom);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme<Theme>();
  const customization = useRecoilValue(customizationState);

  const bestProfitReturnCombinationId = useRecoilValue(bestProfitReturnCombinationIdState);

  if (!howData) {
    throw new Error('howData is undefined');
  }

  if (!combination || !customization) {
    return <HowSkeletonWidget />;
  }

  const cost = combination.costWithoutOwned();
  const sentiment = combination.sentiment();
  const legData = combination.positions;

  const postTradeTicketToHostIfPossible = () => {
    if (!customization?.isEmbeddingPlatform || customization.tradeTicketTriggerPoint !== 'strategy') {
      return;
    }
    const options = combination.positions
      .filter((p) => p.isOptionType)
      .map((p) => {
        return {
          ask: p.ask(),
          bid: p.bid(),
          price: p.price(),
          quantity: p.quantity,
          expiry: p.expiry,
          strike: p.strike,
        };
      });
    const securities = combination.positions
      .filter((p) => p.isSecurityType())
      .map((p) => {
        return {
          ask: p.ask(),
          bid: p.bid(),
          price: p.price(),
          quantity: p.quantity,
        };
      });
    const tradeTicket = {
      symbol: combination.symbol,
      optionLegs: options,
      securityLegs: securities,
      strategyName: combination.strategyName(),
      price: cost,
    };
    const message = { event: EmbedderEventType.TradeTicketExecute.toString(), data: tradeTicket };
    window.parent.postMessage(JSON.stringify(message), '*');
  };

  const onTradeTicket = (e: React.MouseEvent<HTMLButtonElement>) => {
    postTradeTicketToHostIfPossible();
    if (!customization?.showTradeTicket) {
      return;
    }
    setTradeTicket({ combination: combination });
    setTradingStrategySelectedCombinationId(id.toString());
    setSubView('tradeTicket');
    logActivity('BUTTON', 'tradingStrategiesTradeCombinationButtonBelowChart');
  };

  const onModify = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTradingStrategySelectedCombinationId(id.toString());
    setStrategyModify(combination);
    setTradeSimulatorOperation(tradeSimulatorOperationType.Modify);
    logActivity('BUTTON', 'tradingStrategiesEditCombinationButton');
  };

  const onExpand = (_e: React.MouseEvent<HTMLButtonElement>) => {
    setSubView('expand');
    setTradingStrategySelectedCombinationId(id.toString());
    setTradeSimulatorOperation(tradeSimulatorOperationType.ShowRisksandCheckilst);
    logActivity('BUTTON', 'tradingStrategiesExpandButton');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      `${combination.symbol}`,
    );
  };

  const renderStrategySentiment = () => {
    if (combination.positions.length > 0) {
      return (
        <Typography variant="body1" fontWeight="bold">
          <RedGreenWidget value={sentiment}>{t(`app.php.how.tradeIdeaPanel.${sentiment}`)}</RedGreenWidget>
        </Typography>
      );
    }

    if (!combination.isOnlyOptionsWithChain) {
      return <Typography component="p" style={{ paddingLeft: 10, paddingTop: '14%' }}></Typography>;
    }

    return (
      <Typography variant="body1">
        {`${t('app.php.how.tradingStrategies.labels.noOptimal')} ${combination.noCallOptimalCombinationTitle}`}
      </Typography>
    );
  };

  const renderAction = () => {
    if (!customization?.showSingleTrade || legData.length <= 0) {
      return null;
    }
    return (
      <IconButton
        id={`tradingStrategiesExpandButton${id}`}
        className="tradingStrategiesExpandButton"
        aria-label="settings"
        data-name="tradingStrategiesExpandButton"
        data-value=""
        size="small"
        name="expand"
        sx={{ font: '10px', p: '10px' }}
        onClick={onExpand}>
        <OpenInNewIcon id="expand" style={{ color: theme.palette.info.light }} />
      </IconButton>
    );
  };

  const renderStrategyTitle = () => {
    if (combination.hasOnlyStx()) {
      return <CombinationHeadingWidget text={combination.fullNameWithoutSymbol()} />;
    }
    if (!howData.chain) {
      return (
        <CombinationHeadingWidget
          text={`${formatting.symbolDotExchangeToSymbol(combination.symbol.toUpperCase())} has no listed options`}
        />
      );
    }
    if (combination.positions.length === 0) {
      return (
        <CombinationHeadingWidget
          text={`${t('app.php.how.tradingStrategies.labels.noOptimal')} ${renderCustomStrategyName()}`}
        />
      );
    }
    if (combination.positions.length > 0) {
      return <CombinationHeadingWidget text={combination.fullNameWithoutSymbol()} />;
    }
  };

  const renderCustomStrategyName = () => {
    let strategyDisplayedName: string = '';
    if (
      tradingStrategiesSentiment &&
      tradingStrategiesSentiment.toUpperCase() === SentimentType.BULLISH.toUpperCase()
    ) {
      if (id === 1) {
        strategyDisplayedName = 'Long Call';
      } else {
        strategyDisplayedName = 'Long Call Vertical';
      }
    } else {
      if (id === 1) {
        strategyDisplayedName = 'Long Put';
      } else {
        strategyDisplayedName = 'Long Put Vertical';
      }
    }
    return strategyDisplayedName;
  };
  const buildCustomStrategy = () => {
    let strategyDisplayedName = renderCustomStrategyName();
    if (!combination) {
      return;
    }
    const newCombination = StrategyHelpers.generateCombinationByStrategyName(
      strategyDisplayedName,
      howData,
      combination,
    );
    if (!newCombination) {
      throw new Error('Combination is undefined');
    }
    setTradingStrategySelectedCombinationId(id.toString());
    setCombination(newCombination);
    setStrategyModify(newCombination);
  };

  const renderFlipLayout = () => {
    // logActivity('showOptionsPlayScore');
    if (isFlipped) {
      return (
        <Grid container justifyContent="center" alignItems="center" style={{ display: 'block', flexDirection: 'row' }}>
          <GuideItem selector=".riskPnaels_helpPinPlaceholder" canShow={id === 0} />
          <StrategyDataWidget data={combination} />
        </Grid>
      );
    }
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} container columnSpacing={1}>
          <Grid item xs={6}>
            <Typography variant="body1" textAlign="right">
              {t('app.php.how.tradingStrategies.labels.strategy')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {renderStrategySentiment()}
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={1}>
          <Grid item xs={6}>
            <Typography variant="body1" noWrap textAlign="right">
              {t('app.php.how.tradingStrategies.labels.tradeCost')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" id="card-trade-cost">
              {NumberFormatHelper.toCurrency(cost)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProfitLossWidget data={combination} index={id} />
        </Grid>
        <Grid item xs={12}>
          <Suspense fallback={<Skeleton height={100} animation="wave"></Skeleton>}>
            <TradeOptionsPlayScoreWidget index={id} />
          </Suspense>
        </Grid>
      </Grid>
    );
  };

  const renderStrategyDetails = () => {
    if (combination.positions.length === 0 && combination.chain && combination.chain.rows.length > 0) {
      return renderBuildCustomStrategyInformation();
    }

    if (!combination.isOnlyOptionsWithChain) {
      return null;
    }

    return <>{renderFlipLayout()}</>;
  };

  const renderChart = () => {
    if (combination.positions.length === 0 && combination.chain && combination.chain.rows.length > 0) {
      return null;
    }
    if (!combination.isOnlyOptionsWithChain) {
      return null;
    }
    return (
      <React.Fragment>
        <GuideItem selector=".profitAndLossChart_helpPinPlaceholder" canShow={id === 0} />
        <PlChartWidget data={combination} showAxis={false} />
      </React.Fragment>
    );
  };

  const renderBuildCustomStrategyInformation = () => {
    return (
      <Box sx={{ border: '1px solid #aeb4c4' }} p={1}>
        <Typography variant="body1" component="span">{`There are no optimal ${renderCustomStrategyName()} on ${
          howData.quote.name
        } for trading a ${tradingStrategiesSentiment?.toLowerCase()} view at this time.`}</Typography>
        <Typography variant="body1" fontWeight="bold" component="span" px={1}>
          <Link onClick={buildCustomStrategy} href="#">
            <span>{t('app.php.how.howPanel.titles.clickHere')}</span>
          </Link>
        </Typography>
        <Typography variant="body1" component="span">
          {t('app.php.how.howPanel.text.constructCustomStrategy')}
        </Typography>
      </Box>
    );
  };

  const renderStrategyControls = () => {
    if (!combination.isOnlyOptionsWithChain) {
      return null;
    }
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" columnSpacing={1}>
        <GuideItem selector=".tradeButton_helpPinPlaceholder" canShow={id === 0} />
        <Grid item xs={6}>
          <Button
            id={`index${id}`}
            variant="contained"
            name="trade"
            color="primary"
            data-name="tradingStrategiesTradeCombinationButtonBelowChart"
            onClick={onTradeTicket}
            fullWidth>
            <Typography sx={{ color: theme.palette.selectAndTextField.dark }} variant="body1">
              {t(customization.tradeLabel)}
            </Typography>
          </Button>
        </Grid>
        <GuideItem selector=".modifyButton_helpPinPlaceholder" canShow={id === 0} />
        <Grid item xs={6}>
          <Button
            id={`tradingStrategiesEditCombinationButton${id}`}
            className="tradingStrategiesEditCombinationButton"
            color="primary"
            variant={'outlined'}
            name="modify"
            data-name="tradingStrategiesEditCombinationButton"
            data-value=""
            style={{
              backgroundColor:
                tradeSimulatorOperation === tradeSimulatorOperationType.Modify &&
                selectedCombinationId !== id.toString()
                  ? theme.palette.grey[400]
                  : theme.palette.background.paper,
            }}
            onClick={onModify}
            fullWidth>
            <Typography variant="body1">{t('app.php.common.buttons.modify')}</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {id === 2 ? <div className="vertical_spread_panel_step" /> : undefined}
      <StrategyCardWidget
        title={renderStrategyTitle()}
        actionButton={renderAction()}
        bestStrategy={bestProfitReturnCombinationId === id}
        actionArea={renderStrategyDetails()}
        chartWidget={renderChart()}
        strategyControls={renderStrategyControls()}
        isActive={selectedCombinationId === id.toString()}
        disableActionArea={
          !customization?.enableFlipWidget ||
          (combination.positions.length === 0 && combination.chain && combination.chain.rows.length > 0)
        }
      />
    </>
  );
};
