import { Grid, Typography, useTheme } from '@mui/material';
import { IRowProps, IdeaProps, commonProps } from './pager';
import { OpLinkMenus } from './op-link-menu';
import React from 'react';
import { FlagWidget, SentimentShortTermWidget } from '../common';
import formatting from '@op/shared/src/models/how/formatting';
import optionPlayLogo from '@op/shared/assets/images/optionPlayMainLogo.png';
import { useRecoilValue } from 'recoil';
import { customizationState } from '@op/shared/src/states';

export const FirstRow: React.FC<IdeaProps<IRowProps | commonProps>> = ({
  data,
}: IdeaProps<IRowProps | commonProps>) => {
  const showOpLogo = (data as IRowProps).rules && (data as IRowProps).rules.length > 1;
  const theme = useTheme();
  const customization = useRecoilValue(customizationState);

  const renderPortfolioWatchListCount = () => {
    if (!customization || !customization.showTechnicalReports) {
      return;
    }
    return (
      <Grid item container xs={'auto'}>
        <Grid item xs={'auto'}>
          <OpLinkMenus
            data={(data as commonProps)?.portfolios.map((p) => {
              return {
                id: p.id,
                symbol: p.symbol,
                name: p.name,
              };
            })}
            filterType="portfolio"
            title="Available in Portfolio"
            noTitle="No Portfolios under this symbol"
            icon="personalPortfolioIcon"
            hoverIcon="hoverPersonalPortfolioIcon"
            stroke={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={'auto'} paddingTop={0.5}>
          <OpLinkMenus
            data={(data as commonProps)?.watchlists.map((p) => {
              return {
                id: p.id,
                symbol: p.symbol,
                name: p.name,
              };
            })}
            filterType="watchlist"
            title="Available in Watchlist"
            noTitle="Symbol is not availabe in any watchlist"
            icon="personalWatchlistIcon"
            hoverIcon="personalWatchlistIcon"
            stroke={theme.palette.primary.main}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={7} pt={0.2} container>
        <Grid item xs={8}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            lineHeight={1}
            className="js-trade-idea-symbol-dot-exchange-symbol">
            {formatting.symbolDotExchangeToSymbol(data.symbol)}
            {showOpLogo ? (
              <img
                src={optionPlayLogo}
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: '20px',
                  alignSelf: 'center',
                  // verticalAlign: 'middle',
                }}
                alt=""
              />
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FlagWidget exchange={formatting.getExchange(data.symbol)} variant="square" />
        </Grid>
      </Grid>
      <Grid item xs={5} container columnSpacing={1} textAlign={'end'} justifyContent={'flex-end'} alignItems="center">
        {renderPortfolioWatchListCount()}
        <Grid item xs={'auto'}>
          <SentimentShortTermWidget symbol={data.symbol} isIconOnly />
        </Grid>
      </Grid>
    </Grid>
  );
};
