import { ThemeOptions } from '@mui/material';
import { ImplementationType } from '@op/shared/src/models/enums/enums';
import ThemeType from '@op/shared/src/models/theme-type';
import { configurationState } from '@op/shared/src/states';
import { themeState } from '@op/shared/src/states/theme-state';
import { selector } from 'recoil';
import { lightTheme, mlDarkTheme, mlLightTheme, nasdaqTheme, slateTheme } from '../themes';
import {
  questradeBlueTheme,
  questradeDarkTheme,
  questradeLightTheme,
  questradeWebLightTheme,
} from '../themes/questrade-theme';

export const themeSelectorState = selector<ThemeOptions>({
  key: 'themeSelectorStateKey',
  get: ({ get }) => {
    const selectedThemeType = get(themeState);
    const configuration = get(configurationState);
    switch (selectedThemeType) {
      case ThemeType.LIGHT:
        return lightTheme;
      case ThemeType.MLDARK:
        return configuration && configuration.implementation === ImplementationType.MERRILL ? mlDarkTheme : slateTheme;
      case ThemeType.MLLIGHT:
        return mlLightTheme;
      case ThemeType.NASDAQ:
        return nasdaqTheme;
      case ThemeType.QUESTRADELIGHT:
        return questradeLightTheme;
      case ThemeType.QUESTRADEBLUE:
        return questradeBlueTheme;
      case ThemeType.QUESTRADEWEBLIGHT:
        return questradeWebLightTheme;
      case ThemeType.QUESTRADEDARK:
        return questradeDarkTheme;
      default:
        return lightTheme;
    }
  },
});
