import { Grid, Skeleton } from '@mui/material';
import React from 'react';

export const IncomeLoaderWidget: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Skeleton
          animation="wave"
          variant="text"
          height={25}
          width="80%"
          sx={{ mr: 0.5, ml: 1, display: 'inline-block' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={30}
          width="95%"
          sx={{ mr: 0.5, ml: 0.5, display: 'inline-block' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={30}
          width="95%"
          sx={{ mr: 1, ml: 0.5, display: 'inline-block' }}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="flex-start" alignItems="center">
        <Grid item xs={6} mt={1}>
          <Skeleton animation="wave" variant="text" height={20} width="70%" sx={{ mx: 1, display: 'inline-block' }} />
          <Skeleton animation="wave" variant="text" height={20} width="70%" sx={{ mx: 1, display: 'inline-block' }} />
        </Grid>
        <Grid item xs={6} mt={1} textAlign="right">
          <Skeleton animation="wave" variant="text" height={20} width="50%" sx={{ mx: 1, display: 'inline-block' }} />
          <Skeleton animation="wave" variant="text" height={20} width="50%" sx={{ mx: 1, display: 'inline-block' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="text" height={80} sx={{ mx: 1 }} />
        </Grid>
      </Grid>

      <Grid item container xs={12} direction="row" columnSpacing={1} sx={{ px: 1 }}>
        <Grid item xs={6}>
          <Skeleton animation="wave" variant="rectangular" height={180} />
        </Grid>
        <Grid item container xs={6} direction="row" columnSpacing={1} height={150} p={1}>
          {[0, 1, 2, 3, 4, 5, 6, 7].map((value) => (
            <Grid item container key={value} justifyContent="right">
              <Skeleton animation="wave" variant="text" width="40%" height={20} sx={{ mx: 1 }} />
              <Skeleton animation="wave" variant="text" width="40%" height={20} sx={{ mx: 1 }} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="right">
        <Skeleton animation="wave" variant="rounded" height={30} width="25%" sx={{ m: 1, display: 'inline-block' }} />
        <Skeleton animation="wave" variant="rounded" height={30} width="20%" sx={{ m: 1, display: 'inline-block' }} />
      </Grid>
      <Grid item xs={12} sx={{ pt: 1 }}>
        <Skeleton animation="wave" variant="text" height={20} sx={{ mx: 1 }} />
        <Skeleton animation="wave" variant="text" height={20} sx={{ mx: 1 }} />
        <Skeleton animation="wave" variant="text" height={20} sx={{ mx: 1 }} />
      </Grid>
      <br />
      <Grid item container xs={12} justifyContent="flex-start" alignItems="flex-start" sx={{ pt: 1 }}>
        {[0, 1, 2, 3].map((value) => (
          <Grid key={value} item xs={3}>
            <Skeleton animation="wave" variant="text" height={25} sx={{ mr: 0.5, ml: 0.5 }} />
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={12} sx={{ pt: 1 }}>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rectangular" height={250} sx={{ mx: 1 }} />
        </Grid>
      </Grid>
    </Grid>
  );
};
