import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { SentimentType } from '@op/shared/src/models';
import {
  customizationState,
  howDataState,
  previousSelectedSymbolState,
  strategyShareDataState,
  tradingStrategiesSentimentAtom,
  useOnSentimentChange,
} from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

export const StrategySentimentWidget: React.FC = () => {
  const shareRoute = useMatch('/share/*');
  const howData = useRecoilValue(howDataState);
  const [strategySentiment, setStrategySentiment] = useRecoilState(tradingStrategiesSentimentAtom);
  const setPreviousSelectedSymbol = useSetRecoilState(previousSelectedSymbolState('trade'));
  const strategyShareData = useRecoilValue(strategyShareDataState);
  const customization = useRecoilValue(customizationState);
  const onSentimentChange = useOnSentimentChange();
  const { t } = React.useContext(LocalizationContext);

  useEffect(() => {
    /**
     * This useeffect will run only when sentiment is undefined means start of the application/selecting 
      symbol from trade widget or search symbol
      */
    if (shareRoute && strategyShareData?.combinationType === 'Trade') {
      return;
    }
    if (!howData) {
      return;
    }
    if (strategySentiment) {
      return;
    }
    setStrategySentiment(howData.originalSentiment);
    onSentimentChange(howData.originalSentiment);
    setPreviousSelectedSymbol(howData.symbol);
    // return () => resetStrategySentiment();
  }, [strategySentiment]);

  if (!howData || !strategySentiment) {
    return null;
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="select strategy sentiments"
        name="strategySentiments"
        value={strategySentiment}
        onChange={(e, v) => onSentimentChange(v)}
        className="sentimentBtnGroup_helpPinPlaceholder">
        <FormControlLabel
          value={SentimentType.BULLISH}
          id="strategySentimentBullish"
          control={<Radio size={'small'} color="primary" />}
          label={<Typography variant="button">{t('app.php.common.labels.bullish')}</Typography>}
        />
        <FormControlLabel
          value={SentimentType.BEARISH}
          id="strategySentimentBearish"
          control={<Radio size={'small'} color="primary" />}
          label={<Typography variant="button">{t('app.php.common.labels.bearish')}</Typography>}
        />
        {customization?.showNeutralStrategy ? (
          <FormControlLabel
            value={SentimentType.NEUTRAL}
            id="strategySentimentNeutral"
            control={<Radio size={'small'} color="primary" />}
            label={<Typography variant="button">{t('app.php.common.labels.highImpliedVolatility')}</Typography>}
          />
        ) : null}
      </RadioGroup>
    </FormControl>
  );
};
