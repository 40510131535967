import ApplicationContext from './application-context';
import LanguageType from '../languge-type';
import { RiskRewardType } from '../enums/enums';
import lodash from 'lodash';

class NumberFormatHelper {
  static readonly NOT_AVAILABLE = 'N/A';

  // converts e.g. 75.7857 → +75.79%
  static toPercentage = (
    input: number | string | null | undefined,
    signDisplay: 'auto' | 'never' | 'always' | 'exceptZero' = 'auto',
  ) => {
    const option = {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      signDisplay: signDisplay,
    };
    /*This tranformer is temporary.
     * All UI element is sending multiplied by 100 to percentage.
     * So dividing here to get currect value
     */
    const transformer = (value: number) => value / 100;
    return NumberFormatHelper.format(input, option, transformer);
  };

  static almostEqual = (a: number, b: number, precision?: number) => {
    precision = precision || 1e-6;
    const result = Math.abs(a - b) < precision;
    return result;
  };

  static roundNumber = (n: number, precision: number = 2) => {
    if (n === undefined) {
      throw new Error('number is null or undefiend');
    }
    return lodash.round(n, precision);
  };

  static floor = (n: number, precision?: number) => {
    return lodash.floor(n, precision);
  };

  /**
   * Rounds given number x to be multiple of number f
   * @example
   * returns 2.50
   * round(2.47, 0.05);
   * returns 2.45
   * round(2.47, 0.05, true);
   * @param {number} x Number to round
   * @param {number} f Number to round
   * @param {boolean=undefined} if not set - the value is rounded to nearest multiple of 'f'.
   */
  static roundDownToMultiplier = (x: number, f: number) => {
    if (f === 0) {
      return 0;
    }
    return f * Math.floor(x / f);
  };

  static roundUpToMultiplier = (x: number, f: number) => {
    if (f === 0) {
      return 0;
    }
    return f * Math.ceil(x / f);
  };

  private static format = (
    input: number | string | null | undefined,
    option: Intl.NumberFormatOptions,
    transformer?: (input: number) => number,
  ) => {
    let value = NumberFormatHelper.parseNumber(input);
    // Lodash.isNull used for a check to exclude 0 number.
    if (lodash.isNull(value) || lodash.isUndefined(value)) {
      return NumberFormatHelper.NOT_AVAILABLE;
    }
    const locale = ApplicationContext.globalization.name;
    if (transformer) {
      value = transformer(value);
    }
    return Intl.NumberFormat(locale, option).format(value);
  };

  //This will parse string to int, float.
  private static parseNumber = (input: number | string | null | undefined) => {
    if (input === null || input === undefined || lodash.isNaN(input)) {
      return undefined;
    }
    if (lodash.isNumber(input)) {
      return input;
    }
    if (input.trim() === '') {
      return undefined;
    }
    const formatted = input.replace(' ', '').replace(',', '');
    return lodash.toNumber(formatted);
  };

  static toFractionalString = (
    input: number | string | null | undefined,
    signDisplay: 'auto' | 'never' | 'always' | 'exceptZero' = 'auto',
  ) => {
    const option = {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      signDisplay: signDisplay,
    };
    return NumberFormatHelper.format(input, option);
  };

  static toIntString = (
    input: number | string | null | undefined,
    signDisplay: 'auto' | 'never' | 'always' | 'exceptZero' = 'auto',
  ) => {
    const option = {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      signDisplay: signDisplay,
    };
    return NumberFormatHelper.format(input, option);
  };

  static toCurrency = (
    input: number | string | null | undefined,
    signDisplay: 'auto' | 'never' | 'always' | 'exceptZero' = 'auto',
  ) => {
    if (lodash.isString(input) && input.trim().toUpperCase() === 'UNLIMITED') {
      return 'Unlimited';
    }
    const currency =
      ApplicationContext.globalization.name === LanguageType.Swedish
        ? 'SEK'
        : ApplicationContext.globalization.name === LanguageType.FrenchCanadian
        ? 'CAD'
        : 'USD';
    const showCurrencySymbolForNumbers = ApplicationContext.configuration.showCurrencySymbolForNumbers;
    const style = showCurrencySymbolForNumbers ? 'currency' : undefined;
    const option = {
      style: style,
      currency: currency,
      signDisplay: signDisplay,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    let formattedString = NumberFormatHelper.format(input, option);
    return (formattedString || '').replace('US$', '$');
    // return NumberFormatHelper.format(input, option);
    // if (NumberFormatHelper.OTHER_THAN_NORDIC) {
    //   formattedValue = Intl.NumberFormat('fr-FR', {
    //     style: 'currency',
    //     currency: 'EUR',
    //     minimumFractionDigits: minimumFractionDigits,
    //   }).format(parsedValue as unknown as number);
    // }
  };

  static closeTo = (num1: number, num2: number, tolerance: number) => {
    const diff = num1 - num2;
    return Math.abs(diff) <= tolerance;
  };

  static toFormattedVolume = (num: number | null | undefined) => {
    if (!num) return;
    const billion = 1000000000;
    const million = 1000000;
    const billionSign = 'B';
    const millionSign = 'M';
    let result, doubleValue;

    if (num >= billion) {
      doubleValue = num / billion;
      doubleValue = NumberFormatHelper.toFractionalString(doubleValue);
      result = doubleValue + billionSign;
    } else if (num >= million) {
      doubleValue = num / million;
      doubleValue = NumberFormatHelper.toFractionalString(doubleValue);
      result = doubleValue + millionSign;
    } else {
      result = NumberFormatHelper.toFractionalString(num);
    }
    return result;
  };

  static numberWithTrailingZero = (value: number) => {
    return lodash.padStart(value.toString(), 2, '0');
  };

  //TODO: Mobile Added Explicitly to handle rewards and risk numbers
  static parseRewardRiskNumber = (value: any) => {
    if (value === RiskRewardType.UNLIMITED) {
      return 'Unlimited';
    }
    return NumberFormatHelper.toCurrency(Math.abs(value));
  };

  static parseNumberOrReturnNull = (value: string) => {
    if (lodash.isEmpty(value)) {
      return undefined;
    }
    const convertedValue = lodash.toNumber(value);
    if (lodash.isNaN(convertedValue)) {
      return undefined;
    }
    return convertedValue;
  };

  static roundPercentageValue = (input: string) => {
    const formatted = input.replace('%', '');
    const roundedNumber = NumberFormatHelper.roundNumber(lodash.toNumber(formatted), 0);
    return roundedNumber;
  };
}

export default NumberFormatHelper;
