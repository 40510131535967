import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Typography, useTheme } from '@mui/material';
import React from 'react';
import lodash from 'lodash';
import { IChecklistItem } from '@op/shared/src/models';

const previewHeader = {
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
};

const dotSize = 14;
export interface IStrategyChecklistPreviewProps {
  checkListItem: IChecklistItem[] | undefined;
}

export const StrategyChecklistPreviewWidget: React.FC<IStrategyChecklistPreviewProps> = ({
  checkListItem,
}: IStrategyChecklistPreviewProps) => {
  const theme = useTheme();

  const getCustomThemeColor = (color: string) => {
    if (color === 'green') {
      return theme.palette.success.main;
    }
    if (color === 'red') {
      return theme.palette.error.main;
    }
    if (color === '#ffad33') {
      return theme.palette.warning.main;
    }
    return 'inherit';
  };

  const checklistPreview = () => {
    if (!checkListItem) {
      return;
    }
    const items = lodash.uniqBy(checkListItem, (i) => i.type);
    const sorted = lodash.sortBy(items, (i) => i.sortOrder);
    return sorted.map((listItem: IChecklistItem, index: number) => {
      return (
        <>
          <FiberManualRecordIcon
            key={index}
            style={{ color: getCustomThemeColor(listItem.color), width: dotSize, height: dotSize }}
          />
        </>
      );
    });
  };

  return (
    <Typography component="span" style={previewHeader}>
      {checklistPreview()}
    </Typography>
  );
};
