import { Container, Paper } from '@mui/material';
import React, { ReactFragment, ReactNode, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { ScrollableBox } from '../styled/options-play-components';
import { accountState } from '@op/shared/src/states/account-state';
import { useRecoilValue } from 'recoil';
import { SignupModal } from './signup-modal';
import { customizationState } from '@op/shared/src/states';
import { isGlobal } from '@op/shared/src/models';
import { useOPNavigate } from '../states/use-navigate-search';

export const PageContainerWidget: React.FC<{ children: ReactNode | ReactFragment }> = ({
  children,
}: {
  children: ReactNode | ReactFragment;
}) => {
  const account = useRecoilValue(accountState);
  const opNavigate = useOPNavigate();
  const shareRoute = useMatch('/share/*');
  const embedderRoute = useMatch('/embedded/:widgetName');
  const homeRoute = useMatch('/');
  const technicalIdeaRoute = useMatch('/idea/technical-idea/*');
  const ideaRoute = useMatch('/idea/*');
  const watchlistRoute = useMatch('/watchlist');
  const portfolioRoute = useMatch('/portfolio');
  const navigate = useNavigate();
  const location = useLocation();
  const customization = useRecoilValue(customizationState);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  useEffect(() => {
    document.body.classList.add('optionsplay');
    //share route can be accessed anonymously.
    if (shareRoute) {
      return;
    }
    if (!account || !account.canAccessApplication) {
      return;
    }
    if (embedderRoute) {
      processEmbedderRoute();
      return;
    }
    if (homeRoute) {
      processHomeRoute();
      return;
    }
    if (technicalIdeaRoute) {
      processTechnicalIdeaRoute();
      return;
    }
    if (ideaRoute) {
      processIdeaRoute();
      return;
    }
    if (watchlistRoute) {
      processWatchlistRoute();
      return;
    }
    if (portfolioRoute) {
      processPortfolioRoute();
    }
    //Not possible to reach here, If reaches here, then no permission, but its already checked in canAccessApplication.
  });

  const processEmbedderRoute = () => {
    if (!embedderRoute || !account) {
      return;
    }
    if (account.canWorkWithTradeIdeas) {
      return;
    }
    if (account.canWorkWithWatchLists) {
      navigate('/watchlist', { replace: true });
      return;
    }
    if (account.canWorkWithPortfolios) {
      navigate('/portfolio', { replace: true });
      return;
    }
  };

  const processTechnicalIdeaRoute = () => {
    if (!technicalIdeaRoute || !account) {
      return;
    }
    if (customization && account.canWorkWithTradeIdeas) {
      return;
    }
    if (account.canWorkWithTradeIdeas) {
      navigate('/', { replace: true });
      return;
    }
    if (account.canWorkWithWatchLists) {
      navigate('/watchlist', { replace: true });
      return;
    }
    if (account.canWorkWithPortfolios) {
      navigate('/portfolio', { replace: true });
      return;
    }
  };

  const processIdeaRoute = () => {
    if (!ideaRoute || !account) {
      return;
    }
    if (customization && customization.showTechnicalReports && account.canWorkWithTradeIdeas) {
      return;
    }
    if (account.canWorkWithTradeIdeas) {
      navigate('/', { replace: true });
      return;
    }
    if (account.canWorkWithWatchLists) {
      navigate('/watchlist', { replace: true });
      return;
    }
    if (account.canWorkWithPortfolios) {
      navigate('/portfolio', { replace: true });
      return;
    }
  };

  const processHomeRoute = () => {
    if (!homeRoute || !account) {
      return;
    }
    if (account.canWorkWithTradeIdeas) {
      return;
    }
    if (account.canWorkWithWatchLists) {
      navigate('/watchlist', { replace: true });
      return;
    }
    if (account.canWorkWithPortfolios) {
      navigate('/portfolio', { replace: true });
      return;
    }
  };

  const processWatchlistRoute = () => {
    if (!watchlistRoute || !account) {
      return;
    }
    if (account.canWorkWithWatchLists) {
      return;
    }
    if (account.canWorkWithTradeIdeas) {
      navigate('/', { replace: true });
      return;
    }
    if (account.canWorkWithPortfolios) {
      navigate('/portfolio', { replace: true });
      return;
    }
  };

  const processPortfolioRoute = () => {
    if (!portfolioRoute || !account) {
      return;
    }
    if (account.canWorkWithPortfolios) {
      return;
    }
    if (account.canWorkWithTradeIdeas) {
      navigate('/', { replace: true });
      return;
    }
    if (account.canWorkWithWatchLists) {
      navigate('/watchlist', { replace: true });
      return;
    }
  };

  const onClickSignUp = () => {
    // const shareId = shareRoute?.params['*'] || '';
    // navigate(`/registration?returnUrl=share/${shareId}`);
    opNavigate('/registration');
  };

  const onClickAccount = () => {
    // const shareId = shareRoute?.params['*'] || '';
    // navigate(`/login?returnUrl=share/${shareId}`);
    opNavigate('/login');
  };

  const onClickAround = () => {
    if (account || isSignUpModalOpen) {
      return;
    }
    setIsSignUpModalOpen(true);
  };

  return (
    <div onClick={() => onClickAround()}>
      <Container sx={{ pointerEvents: account ? 'auto' : 'none' }} maxWidth="xl">
        {children}
      </Container>
      <SignupModal
        isOpen={isSignUpModalOpen}
        onHandleClose={() => {
          // setCanInteract(false);
          setIsSignUpModalOpen(false);
        }}
        onClickSignUp={onClickSignUp}
        onClickAccount={() => onClickAccount()}
      />
    </div>
  );
};

export interface IHeightWidgetProps extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode | ReactFragment;
  height?: number;
  noScroll?: boolean;
  sx?: any;
}

//TODO: NOTE: `border: '1px solid transparent'` is a hack to keep equal height for what-widget. Should find actual reason.
export const HeightWidget: React.FC<IHeightWidgetProps> = ({ children, height, noScroll, sx }: IHeightWidgetProps) => {
  let sxProps = [];
  const resultantHeight = height || 800;
  if (!sx) {
    sxProps = [{ height: `${resultantHeight}px`, border: '1px solid transparent', overflow: 'hidden' }];
  } else {
    sxProps = [
      { height: `${resultantHeight}px`, border: '1px solid transparent' },
      ...(Array.isArray(sx) ? sx : [sx]), // We cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
    ];
  }
  return (
    <Paper sx={sxProps}>
      {noScroll ? <>{children}</> : <ScrollableBox height={`${resultantHeight}px`}>{children}</ScrollableBox>}
    </Paper>
  );
};
