import { Button, Grid, InputAdornment, Skeleton, Typography, useTheme } from '@mui/material';
import { PortfolioManagementCombination, PortfoliouploadData, SavePortfolioRequest } from '@op/shared/src/models';
import { PortfolioType } from '@op/shared/src/models/enums/enums';
import { Combination, IncomeCombination, Position } from '@op/shared/src/models/how';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { PortfolioPositionsService } from '@op/shared/src/services';
import {
  accountState,
  filtersWatchListState,
  portfolioSaveTradeAccountsState,
  portfolioSelectedCombinationState,
  portfoliosDataState,
  tradeTicketCombinationState,
  tradesCachedState,
  watchListsCachedState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import {
  MenuProps,
  OPBoldTypograpghyforCreate,
  OPLightTypograpghy,
  OPSelectDropDownHeader,
  OPSelectField,
  OptionsPlayTextField,
  OutlinedButton,
  StyledDropdownItemWithBorder,
} from '../styled';
import { AccountSelectIconItemWidget } from '../styled/op-icon-select-component-widget';

import DoneIcon from '@mui/icons-material/Done';
import { coveredCallDataCachedState } from '@op/shared/src/states/reports/covered-call-states';
import { creditSpreadDataCachedState } from '@op/shared/src/states/reports/credit-spread-states';
import { shortPutDataCachedState } from '@op/shared/src/states/reports/short-put-states';
import { debounce } from 'lodash';
import { useMatch } from 'react-router-dom';
import formatting from '@op/shared/src/models/how/formatting';

export interface ISavePositionsProps {
  combination: Combination | IncomeCombination | PortfolioManagementCombination;
  isSaveTradeEnabled: boolean;
}

export interface ILoadingButton {
  conditon: boolean;
  isDisabled: boolean;
  onClick: () => void;
  label: React.ReactNode;
  successLabel: React.ReactNode;
}

export const LoadingButton: React.FC<ILoadingButton> = ({
  conditon,
  isDisabled,
  onClick,
  label,
  successLabel,
}: ILoadingButton) => {
  if (conditon) {
    return (
      <Button variant="contained" color="success" fullWidth sx={{ pl: 1, pr: 0.5 }} startIcon={<DoneIcon />}>
        <OPLightTypograpghy>{successLabel}</OPLightTypograpghy>
      </Button>
    );
  }
  return (
    <Button
      variant="contained"
      fullWidth
      disabled={isDisabled}
      onClick={onClick}
      startIcon={<IconComponent name={'plusAddPositionTo'} stroke={isDisabled ? '#00000033' : '#ffffff'} />}>
      <OPLightTypograpghy>{label}</OPLightTypograpghy>
    </Button>
  );
};

export const AddPositionSkeleton = () => {
  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={3}>
        <Skeleton animation="wave" variant="rectangular" height={35} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton animation="wave" variant="rectangular" height={35} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton animation="wave" variant="rectangular" height={35} />
      </Grid>
    </Grid>
  );
};

export const SavePositionsWidget: React.FC<ISavePositionsProps> = ({
  combination,
  isSaveTradeEnabled,
}: ISavePositionsProps) => {
  const account = useRecoilValue(accountState);
  const tradeTicketCombination = useRecoilValue(tradeTicketCombinationState);
  const portfolioSelectedCombination = useRecoilValue(portfolioSelectedCombinationState);
  const [porfolioSaveTradeAccounts, setPortfolioSaveTradeAccounts] = useRecoilState(portfolioSaveTradeAccountsState);
  const resetPortfolioSaveTradeAccounts = useResetRecoilState(portfolioSaveTradeAccountsState);
  const resetPortfoliosData = useResetRecoilState(portfoliosDataState);
  const resetWatchlists = useResetRecoilState(watchListsCachedState);
  const resetWatchlistFilters = useResetRecoilState(filtersWatchListState);
  const resetTradeIdeas = useResetRecoilState(tradesCachedState);
  const resetCreditSpreads = useResetRecoilState(creditSpreadDataCachedState);
  const resetCoveredCalls = useResetRecoilState(coveredCallDataCachedState);
  const resetShortPuts = useResetRecoilState(shortPutDataCachedState);
  const setNotifications = useSetRecoilState(notificationsState);
  const { t } = React.useContext(LocalizationContext);

  const [portfolioList, setPortfolioList] = useState<any>([]);
  const [selectedPortfolioName, setSelectedPortfolioName] = useState('');
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<number | undefined>();
  const [showSaveTrade, setShowSaveTrade] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPositionAdded, setIsPositionAdded] = useState(false);
  const [isPositionCreated, setIsPositionCreated] = useState('');
  const [showCreatePortfolio, setShowCreatePortfolio] = useState(false);
  const [errorString, setErrorString] = useState<string | undefined>();
  const [portfolioName, setPortfolioName] = React.useState('');

  const theme = useTheme();
  const portfolioRoute = useMatch('/portfolio');
  const ENTER_NEW_PORTFOLIO = 'Enter New Portfolio Name';
  const PORTFOLIO_NAME_IS_REQUIRED = 'Portfolio Name is Required';

  useEffect(() => {
    (async () => {
      await getPortfolioAccounts();
    })();
  }, [porfolioSaveTradeAccounts]);

  useEffect(() => {
    if (isPositionCreated === 'true' || isPositionCreated === '') {
      return;
    }
    resetPortfolioSaveTradeAccounts();
    setShowSaveTrade(false);
    setShowCreatePortfolio(false);
  }, [isPositionCreated]);

  const getPortfolioAccounts = async () => {
    if (!isSaveTradeEnabled || !combination || !tradeTicketCombination || !tradeTicketCombination.combination) {
      return;
    }
    let portfolioAccounts: PortfoliouploadData[] = [];
    if (!porfolioSaveTradeAccounts) {
      const response = await PortfolioPositionsService.instance.InvestmentPositions();
      if (response.hasErrors || !response.data || !response.data.data || response.data.data.length === 0) {
        setShowSaveTrade(true);
        return;
      }
      portfolioAccounts = getEnabledPortfolioAccounts(response.data.data);
      setPortfolioSaveTradeAccounts(portfolioAccounts);
    } else {
      portfolioAccounts = getEnabledPortfolioAccounts(porfolioSaveTradeAccounts);
    }

    if (tradeTicketCombination.combination.isPortfolio && portfolioAccounts.length > 0) {
      const selectedPortfolioAccount = portfolioAccounts.filter(
        (p) => p.enabled && p.id === portfolioSelectedCombination?.portfolioId,
      );
      portfolioAccounts = selectedPortfolioAccount;
    }

    if (!account?.canSeeGlobalPortfolios) {
      const nonGlobalPortfolioAccounts = portfolioAccounts.filter((p) => !p.isGlobal);
      portfolioAccounts = nonGlobalPortfolioAccounts;
    }

    //exclude synced accounts
    const nonSyncedAccounts = portfolioAccounts.filter((p) => p.portfolioTypeStatus !== PortfolioType.TDAmeritrade);
    portfolioAccounts = nonSyncedAccounts;

    if (portfolioAccounts.length === 0) {
      /**
       * Below condition is to explicitly show the Create account view by default,
       * case 1 : user not enabled any accounts from manage portfolio
       * case 2 : For new user with zero portfolio accounts
       */
      setShowSaveTrade(true);
      return;
    }
    //show all accounts everywhere except porfolio management which shows owned porfolio
    setPortfolioList(portfolioAccounts);

    /**
     * Below condition is to explicitly show the account by default,
     * in portfolio as only account is shown for the selected position
     */

    if (tradeTicketCombination.combination.isPortfolio) {
      setSelectedPortfolioName(portfolioAccounts[0].name);
      setSelectedPortfolioId(portfolioAccounts[0].id);
    }
    setShowSaveTrade(true);
  };

  const getEnabledPortfolioAccounts = (portfolioAccounts: PortfoliouploadData[]) => {
    return portfolioAccounts.filter((p) => p.enabled);
  };

  const handleSelect = (e: any) => {
    let val: string | undefined = '';
    portfolioList.map((i: any) => {
      if (i.id === e.target.value) {
        val = i.name;
      }
    });
    setSelectedPortfolioName(val);
    setSelectedPortfolioId(e.target.value);
  };

  const formatExpiry = (date: Date | undefined) => {
    if (!date) {
      return;
    }
    let expiry = new Date(date);
    let yyyy = expiry.getFullYear();
    let mm = expiry.getMonth() + 1;
    let dd = expiry.getDate();
    return `${yyyy}-${mm}-${dd}`;
  };

  const savePortfolioPositions = async () => {
    let posObject: any[] = [];
    let duplicatePositions = new Set();
    let positions = combination.positions;
    if (combination.isIncome) {
      positions = combination.buyablePositions;
    }
    positions.map((pos: Position) => {
      let costBasisTotal = pos.quantity * pos.premiumMultiplier() * pos.price();
      let obj = {
        id: '',
        symbol: combination.symbol,
        quantity: pos.quantity,
        costBasisTotal: costBasisTotal,
        legType: pos.legType,
        expiry: formatExpiry(pos.expiry),
        strike: pos.strike,
        isNew: true,
        markedAsDeleted: false,
      };
      posObject.push(obj);
      // const key = `${pos.symbol}${DateTimeHelper.toExpiryKey(pos.expiry)}${pos.strike}${pos.legType}`.replaceAll(
      //   'null',
      //   '',
      // );
      const key = formatting.customReplaceAll(
        `${pos.symbol}${DateTimeHelper.toExpiryKey(pos.expiry)}${pos.strike}${pos.legType}`,
        'null',
        '',
      );
      duplicatePositions.add(key);
    });
    if (duplicatePositions.size !== positions.length) {
      setNotifications([{ type: 'error', content: 'Duplicate positions found' }]);
      return;
    }
    const portfolioSaveInput = {
      portfolioPositions: posObject,
      totalRecords: posObject.length,
      portfolioName: selectedPortfolioName,
      portfolioId: selectedPortfolioId || getPortfolioId(),
      isManualSelection: false,
    };
    const res = await PortfolioPositionsService.instance.savePortfolioPositions(
      portfolioSaveInput as unknown as SavePortfolioRequest,
    );
    if (!res) {
      setNotifications([{ type: 'error', content: 'Unexpected error. Please try again.' }]);
      return;
    }
    if ((res.data as unknown as string).toLowerCase() !== 'none') {
      setNotifications([{ type: 'error', content: res.data as unknown as string }]);
      return;
    }
    resetPortfoliosData();
    setIsPositionAdded(true);
    /*
      Reason:
        total symbols = 15, if filters applied, and linking the symbol to portfolio (selectedWatchList & originalWatchList = 2)
        and delete a symbol, returns (selectedWatchList=1 & originalWatchList = 2), on removing filters, not going back to original state as having filtered data.
      Fix:
        while adding symbol from company-search in watchlist, we're resetting the filters of watchlist
        should reset the filters on linking to portfolio from trade-ticket (add portfolio positions) to maintain same behaviour.
    */
    resetWatchlistFilters();
    resetWatchlists();
    resetTradeIdeas();
    resetCreditSpreads();
    resetCoveredCalls();
    resetShortPuts();
    setTimeout(() => {
      setIsPositionAdded(false);
    }, 5000);
    setNotifications([{ type: 'success', content: 'Position Added' }]);
  };

  const debounceSavePortfolioPositions = debounce(savePortfolioPositions, 1000);

  const saveNewPortfolio = async () => {
    if (portfolioName === ENTER_NEW_PORTFOLIO || portfolioName.trim() === '') {
      setNotifications([{ type: 'error', content: PORTFOLIO_NAME_IS_REQUIRED }]);
      return;
    }
    const portfolioSaveInput = {
      portfolioPositions: [],
      totalRecords: 0,
      portfolioName: portfolioName,
      portfolioId: 0,
      isManualSelection: true,
    };
    const res = await PortfolioPositionsService.instance.savePortfolioPositions(
      portfolioSaveInput as unknown as SavePortfolioRequest,
    );
    if ((res.data as unknown as string).toLowerCase() !== 'none') {
      if ((res.data as unknown as string) === 'Portfolio Name Exists') {
        setErrorString('Portfolio Name Exists'.replace('Name', `"${portfolioName}" Already`));
        return;
      }
      setErrorString(res.data as unknown as string);
      return;
    }
    setSelectedPortfolioName(portfolioName);
    setSelectedPortfolioId(undefined);
    if (!portfolioRoute) {
      resetPortfoliosData();
    }
    setIsPositionCreated('true');
    setTimeout(() => {
      setIsPositionCreated('false');
    }, 3000);
  };

  const debouncedSaveNewPortfolio = debounce(saveNewPortfolio, 1000);

  const getPortfolioId = () => {
    let val: string | undefined = '';
    portfolioList.map((i: any) => {
      if (i.name === selectedPortfolioName) {
        val = i.id;
      }
    });
    return val;
  };

  const isTDAccountSelected = () => {
    return (combination as PortfolioManagementCombination)?.portfolioTypeStatus === PortfolioType.TDAmeritrade;
  };

  if (!isSaveTradeEnabled || isTDAccountSelected()) {
    return null;
  }
  if (!showSaveTrade) {
    return <AddPositionSkeleton />;
  }

  const renderSelectPortfolioDropdownView = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <OPSelectField
            variant="outlined"
            value={selectedPortfolioId}
            renderValue={() => (selectedPortfolioName === '' ? 'Select / Create New Portfolio' : selectedPortfolioName)}
            onChange={(e: any) => handleSelect(e)}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            displayEmpty
            startAdornment={
              <InputAdornment position="start">
                {isOpen ? (
                  <IconComponent name="portfolioOpen" stroke={theme.palette.primary.main} />
                ) : (
                  <IconComponent name="portfolioClosed" stroke={theme.palette.primary.main} />
                )}
              </InputAdornment>
            }
            MenuProps={MenuProps}>
            <OPSelectDropDownHeader type="tradeTicket" />
            {tradeTicketCombination.combination.isPortfolio ? null : (
              <StyledDropdownItemWithBorder
                color="default"
                key={-1}
                value={''}
                onClick={() => {
                  setShowCreatePortfolio(true);
                  setIsPositionCreated('');
                }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item xs={1} pt={0.5}>
                    <AccountSelectIconItemWidget isGlobal={false} isSelected={false} />
                  </Grid>
                  <Grid item xs={9}>
                    <OPBoldTypograpghyforCreate>{'Create a New Personal Portfolio'}</OPBoldTypograpghyforCreate>
                  </Grid>
                  <Grid item xs={2} textAlign={'end'} pt={0.5}>
                    <IconComponent name="defaultAddIcon" hoverIcon="blueFilledAddIcon" size={24} />
                  </Grid>
                </Grid>
              </StyledDropdownItemWithBorder>
            )}
            {portfolioList.map((item: { id: number; name: string; isGlobal: boolean }, index: any) => {
              const isSelected = selectedPortfolioId === item.id; //portfolioList.indexOf(item.id) > -1;
              return (
                <StyledDropdownItemWithBorder color="default" key={item.id} value={item.id} selected={isSelected}>
                  <Grid container alignItems={'center'}>
                    <Grid item xs={1} pt={0.5}>
                      <AccountSelectIconItemWidget
                        isGlobal={item.isGlobal}
                        isSelected={isSelected}
                        portfolioTypeStatus={item.name}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="inherit">{item.name.trim()}</Typography>
                    </Grid>
                  </Grid>
                </StyledDropdownItemWithBorder>
              );
            })}
          </OPSelectField>
        </Grid>
        <Grid item xs={4} sx={{ pl: 2 }}>
          <LoadingButton
            conditon={isPositionAdded}
            isDisabled={selectedPortfolioName?.trim() === ''}
            onClick={debounceSavePortfolioPositions}
            label={t('app.php.tradeTicket.addPosition')}
            successLabel={'Position Added'}
          />
        </Grid>
      </Grid>
    );
  };

  const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setErrorString(PORTFOLIO_NAME_IS_REQUIRED);
    }
    setErrorString(undefined);
    setPortfolioName(e.target.value);
  };

  const changeSymbol = (event: any) => {
    if (event.key === 'Enter') {
      setPortfolioName(event.target.value);
    }
  };

  const portfolioNameCheck = () => {
    if (portfolioName === ENTER_NEW_PORTFOLIO || portfolioName === '') {
      setErrorString(PORTFOLIO_NAME_IS_REQUIRED);
    } else {
      setErrorString(undefined);
    }
  };

  const renderCreatePortfolioView = () => {
    return (
      <Grid container columnSpacing={1} justifyContent={'space-between'}>
        <Grid item xs={6}>
          <OptionsPlayTextField
            fullWidth
            id="portfolio-symbol"
            name="portfolio symbol"
            type="text"
            variant="outlined"
            placeholder="Enter New Portfolio Name"
            error={errorString !== undefined}
            onBlur={portfolioNameCheck}
            onFocus={() => setPortfolioName('')}
            onChange={(e) => onChangeName(e)}
            value={portfolioName}
            onKeyPress={(event: any) => {
              changeSymbol(event);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ px: 1, py: 2, backgroundColor: theme.palette.info.dark }}>
                  <IconComponent name="portfolioClosed" stroke={theme.palette.primary.main} size={20} />
                </InputAdornment>
              ),
            }}
            FormHelperTextProps={{
              sx: {
                height: 0,
              },
            }}
            helperText={!errorString ? '' : errorString}
          />
        </Grid>
        <Grid item xs={isPositionCreated ? 6 : 3}>
          <LoadingButton
            conditon={isPositionCreated === 'true'}
            isDisabled={portfolioName === ENTER_NEW_PORTFOLIO || portfolioName?.trim() === ''}
            onClick={debouncedSaveNewPortfolio}
            label={t('app.php.why.buttons.create')}
            successLabel={'Portfolio Created'}
          />
        </Grid>
        {isPositionCreated !== '' ? null : (
          <Grid item xs={3}>
            <OutlinedButton
              fullWidth
              id="cancel_create_portfolio"
              color="primary"
              onClick={() => {
                setErrorString(undefined);
                setPortfolioName('');
                setShowCreatePortfolio(false);
              }}
              startIcon={<IconComponent name={'cancel'} stroke={theme.palette.primary.main} />}>
              <Typography variant="button">{t('app.php.common.buttons.cancel')}</Typography>
            </OutlinedButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="space-between" alignItems={'center'}>
      <Grid item xs={2}>
        <Typography>
          <strong>{t('app.php.tradeTicket.addPositionTo')}:</strong>
        </Typography>
      </Grid>
      <Grid item xs={10} py={1}>
        {showCreatePortfolio ? renderCreatePortfolioView() : renderSelectPortfolioDropdownView()}
      </Grid>
    </Grid>
  );
};
