import { createTheme, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { LanguageType, ThemeType } from '@op/shared/src/models';
import { ImplementationType } from '@op/shared/src/models/enums/enums';
import { configurationState, globalizationState, themeState } from '@op/shared/src/states';
import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { NotificationWidget } from './common/notification-widget';
import LocalizationProvider from './react-i18next/localization-provider';
import RouterWidget from './routes/router-widget';
import { DisclaimerWidget } from './side-menu/disclaimer-widget';
import { themeSelectorState } from './states/theme-state';

export const App = (): JSX.Element => {
  const themeOptions = useRecoilValue(themeSelectorState);
  const themeType = useRecoilValue(themeState);
  const configuration = useRecoilValue(configurationState);
  const globalization = useRecoilValue(globalizationState);
  const [globalTheme, setGlobalTheme] = useState<Theme>(createTheme(themeOptions));
  let theme = createTheme(themeOptions);
  // // Most of the text of application uses `body2` variant. Adjust the other variant fontSizes here accordingly.

  React.useEffect(() => {
    for (let themeName in ThemeType) {
      document.body.classList.remove(`body-background-${ThemeType[themeName as keyof typeof ThemeType]}`);
    }
    document.body.classList.add(`body-background-${themeType}`);
    if (configuration && configuration?.implementation !== ImplementationType.MERRILL) {
      theme.typography.body1 = {
        [theme.breakpoints.down('xl')]: {
          fontSize: '0.8rem',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '0.9rem',
        },
      };
      theme.typography.button = {
        textTransform: 'uppercase',
        [theme.breakpoints.down('xl')]: {
          fontSize: '0.80rem',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '0.90rem',
        },
      };
    }
    if (configuration && globalization && globalization.active?.name === LanguageType.Swedish) {
      theme.typography.captionSmall = {
        [theme.breakpoints.down('xl')]: {
          fontSize: '0.5rem',
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '0.75rem',
        },
      };
    }
    const style = document.createElement('style');
    style.textContent = `.Mui-selected.MuiPickersDay-dayWithMargin { background-color:${theme.palette.primary.main}  !important; }`;
    document.head.appendChild(style);
    setGlobalTheme(theme);
  }, [themeType, globalization, configuration]);

  //const responsiveFontTheme = responsiveFontSizes(theme);
  const url = window.location.href;
  if (url.includes('#share')) {
    window.location.href = url.replace('#share', 'share');
  }
  if (url.includes('#strategy')) {
    window.location.href = url.replace('#strategy', 'share');
  }

  return (
    <Suspense fallback={<></>}>
      <LocalizationProvider>
        <ThemeProvider theme={globalTheme}>
          <CssBaseline />
          <NotificationWidget />
          <Router>
            <RouterWidget />
            <DisclaimerWidget showTabName={false} />
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </Suspense>
  );
};
