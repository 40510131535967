import { Grid, useTheme } from '@mui/material';
import { howWhyRanksDataState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { SentimentLongTermWidget, SentimentShortTermWidget, TrendingWidget } from '../common';
import { HeightWidget } from '../routes';
import { DivYieldWidget } from './components/div-yield-widget';
import { EarningsDateWidget } from './components/earnings-date-widget';
import { EpsWidget } from './components/eps-widget';
import { VolumeWidget } from './components/volume-widget';
import { WeekRangeWidget } from './components/week-range';
import { QuotebarSearchWidget } from './quote-bar-search-widget';
import { GuideItem } from '../side-menu';

/**
 * This variant is used specifically for Global environment
 */

export const QuotebarVariant3Widget: React.FC = () => {
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const theme = useTheme();
  const symbolWidgetHeight = 100;

  if (!howWhyRanksData.how || !howWhyRanksData.symbol) {
    return null;
  }

  return (
    <HeightWidget id="symbol-widget-content" className="quotebar-variant2" height={symbolWidgetHeight} noScroll>
      <GuideItem selector=".sentiment_helpPinPlaceholder" />
      <TrendingWidget sentiment={howWhyRanksData.how.originalSentiment} symbol={howWhyRanksData.symbol || ''}>
        <Grid container rowSpacing={0.5}>
          <GuideItem selector=".symbolInput_helpPinPlaceholder" />
          <Grid item xs={12}>
            <QuotebarSearchWidget iconBorder />
          </Grid>
          <Grid item container xs={12} pt={0.5}>
            <Grid item xs={4} sx={{ borderRight: `1px solid ${theme.palette.divider}`, pr: 1 }} id="quotebar-section-1">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <WeekRangeWidget />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <DivYieldWidget />
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ borderRight: `1px solid ${theme.palette.divider}`, pl: 1, pr: 1 }}
              id="quotebar-section-2">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <VolumeWidget />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <EpsWidget />
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ pl: 0.5, pr: 0.5 }} id="quotebar-section-3">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <GuideItem selector=".termAnalysis_helpPinPlaceholder" />
                <Grid item>
                  <SentimentShortTermWidget isQuoteBar symbol={howWhyRanksData.symbol} />
                </Grid>
                <Grid item>
                  <SentimentLongTermWidget isQuoteBar symbol={howWhyRanksData.symbol} />
                </Grid>
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <EarningsDateWidget />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TrendingWidget>
    </HeightWidget>
  );
};
