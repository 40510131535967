/**!
 *	8.8.3
 *	Generation date: 2023-10-27T14:26:09.380Z
 *	Client name: options play
 *	Package Type: Core alacarte
 *	License type: annual
 *	Expiration date: "2024/11/01"
 *	Domain lock: ["127.0.0.1","localhost","optionsplay.com","optionsplay.se","optionsplay.ca"]
 *	Features: SignalIQ,StudyBrowser,Technical Analysis,TypeScript Definitions
 */

import React, { useEffect, useState } from 'react';
import Chart, { CIQ } from '.';

// ChartIQ example resources for markets and translations.
// Replace it with your own or feel free to use ours.
// @ts-ignore
// import quoteFeedSimulator from "chartiq/examples/feeds/quoteFeedSimulator";
// @ts-ignore
// import marker from "chartiq/examples/markers/markersSample";
import {
  configurationState,
  customizationState,
  isDataLoadingState,
  selectedSymbolState,
  themeState,
  whyDataState,
} from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';
import { themeSelectorState } from '../states/theme-state';
import { getChartIQQuestTradeTheme } from '../themes/chartiq-theme';
import { SkeletonLoadingChartIQ } from '../why/mid-panel-skeleton';
import { ChartModalWidget } from './chart-modal-widget';
import { historicalChartState } from './chart-state';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { Typography } from '@mui/material';

export { CIQ };

export interface IChartWidgetProps {}

export const ChartWidget: React.FC<IChartWidgetProps> = () => {
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const whyData = useRecoilValue(whyDataState);
  const settings = useRecoilValue(historicalChartState);
  const [resolved, setResolved] = useState(false);
  const theme = useRecoilValue(themeSelectorState);
  const cutomization = useRecoilValue(customizationState);
  const configuration = useRecoilValue(configurationState);
  const [show, setShow] = useState(0);
  const selectedTheme = useRecoilValue(themeState);
  const { getItem } = localStorage;
  const chartIQThemeOptions = getChartIQQuestTradeTheme(selectedTheme);

  /**
   * !NOTE- It's a Hack. Its should be fixed with proper chartIQ theme APIs
   * We are overwriting the getItem of localstorage to return our chartIQ custom theme object.
   * Internally, when chartIQ-JS make a api call to read the localstorage for the theme Object with the key
   *  `themes__core-chartCIQ.Themes.prototype.current`
   *  `CIQ.Themes.prototype.custom`
   * The overwritten function returns our object. Hence loading the theme.
   * For other storage keys, falling back to browser native implemetation.
   * Hence No side effects for other keys.
   */

  localStorage.getItem = function (key) {
    if (chartIQThemeOptions) {
      if (key === 'themes__core-chartCIQ.Themes.prototype.current') {
        return JSON.stringify(chartIQThemeOptions.name);
      }
      if (key === 'CIQ.Themes.prototype.custom') {
        return JSON.stringify(chartIQThemeOptions.value);
      }
    }
    return getItem.call(this, key);
  };

  useEffect(() => {
    if (configuration && DateTimeHelper.isDateExpired(configuration.chartIqLicenseExpiry)) {
      return;
    }
    // Preload advance and related modules prior invoking ChartExample if available
    // to upgrade ChartExample to AdvancedExample equivalent
    Promise.allSettled([
      import('chartiq/js/advanced.js'),
      // @ts-ignore
      import('chartiq/plugins/signaliq/signaliq.js'),
      // @ts-ignore
      // import('chartiq/plugins/signaliq/signaliqDialog.js'),
      // @ts-ignore
      // import('chartiq/plugins/signaliq/signaliq-marker.js'),
      // @ts-ignore
      // import('chartiq/plugins/signaliq/signaliq-paintbar.js'),
      // Symbol mapping to market definition
      //@ts-ignore
      import('chartiq/examples/markets/marketDefinitionsSample.js'),
      //@ts-ignore
      import('chartiq/examples/markets/marketSymbologySample.js'),
      // Translation file
      //@ts-ignore
      // import("chartiq/examples/translations/translationSample"),
      // Example Marker files
      //@ts-ignore
      // import("chartiq/examples/markers/tradeAnalyticsSample"),
      //@ts-ignore
      // import("chartiq/examples/markers/videoSample")
    ])
      .then(() => setResolved(true))
      .catch(() => setResolved(false));
  }, []);

  useEffect(() => {
    setShow((prev) => prev + 1);
    setTimeout(() => {
      setShow((prev) => prev + 1);
    }, 100);
  }, [settings.showSRLine]);

  if (configuration && DateTimeHelper.isDateExpired(configuration.chartIqLicenseExpiry)) {
    return (
      <div style={{ height: settings.chartAreaHeight + 48, position: 'relative', textAlign: 'center', padding: '25%' }}>
        <Typography variant="h6">There was an error loading the chart. Our developers have been notified.</Typography>
      </div>
    );
  }

  if (!resolved || !whyData || isDataLoading || show % 2 !== 0) {
    return <SkeletonLoadingChartIQ />;
  }

  const mode = theme.palette?.mode || 'light';

  if (settings.isExpanded) {
    return (
      <ChartModalWidget>
        <div style={{ height: settings.chartAreaHeight + 50, position: 'relative', border: '1px solid #b3b0b0' }}>
          <Chart
            symbol={selectedSymbol || ''}
            whyData={whyData}
            panelHeight={null}
            theme={mode === 'light' ? 'ciq-day' : 'ciq-night'}
            showSRLine={settings.showSRLine}
            enableCiqPreferences={cutomization && cutomization.enableCiqPreferences}
          />
        </div>
      </ChartModalWidget>
    );
  }

  return (
    <div style={{ height: settings.chartAreaHeight + 48, position: 'relative', border: '1px solid rgb(124 119 119)' }}>
      <Chart
        symbol={selectedSymbol || ''}
        whyData={whyData}
        panelHeight={100}
        theme={mode === 'light' ? 'ciq-day' : 'ciq-night'}
        showSRLine={settings.showSRLine}
        enableCiqPreferences={cutomization && cutomization.enableCiqPreferences}
      />
    </div>
  );
};
