import { guardRecoilDefaultValue } from '@op/shared/src/states';
import { selector, atom } from 'recoil';
import { IWindowSize } from '../states/use-window-size';

const userChartSettings = 'user-chart-settings';

interface ISettings {
  modalSize: 'sm' | 'md' | 'lg';
  isExpanded: boolean;
  chartAreaHeight: number;
  range: '1M' | '3M' | '6M';
  showSRLine: boolean;
}

export const defaultChartAreaHeight = 375;

export const historicalChartCachedState = atom<ISettings | undefined>({
  key: 'historicalChartCachedStateKey',
  default: undefined,
});

export const historicalChartState = selector<ISettings>({
  key: 'historicalChartStateKey',
  get: ({ get }) => {
    const settings = get(historicalChartCachedState);
    if (settings) {
      return settings;
    }
    const storage = window.localStorage.getItem(userChartSettings);
    if (!storage) {
      return {
        isExpanded: false,
        modalSize: 'md',
        chartAreaHeight: defaultChartAreaHeight,
        range: '3M',
        showSRLine: false,
      } as ISettings;
    }
    const json = JSON.parse(storage);
    json['chartAreaHeight'] = defaultChartAreaHeight;
    json['isExpanded'] = false;
    json['showSRLine'] = false;
    return json as ISettings;
  },
  set: ({ set }, newValue) => {
    if (guardRecoilDefaultValue(newValue)) {
      return;
    }
    const newSetting = {
      modalSize: newValue.modalSize,
      range: newValue.range,
    };
    const settings = JSON.stringify(newSetting);
    window.localStorage.setItem(userChartSettings, settings);
    set(historicalChartCachedState, newValue);
  },
});

export const getChartHeight = (sizeName: string, windowSize: IWindowSize | undefined) => {
  if (!windowSize) {
    return 60;
  }
  let percentage = 60;
  if (sizeName === 'sm') {
    percentage = 60;
  } else if (sizeName === 'md') {
    percentage = 70;
  } else {
    percentage = 94;
  }
  const heightInPx = (windowSize.height * percentage) / 100;
  return heightInPx;
};
