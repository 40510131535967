import { Grid, Typography, useTheme } from '@mui/material';
import SingleTrade from '@op/shared/src/models/how/single-trade';
import { tradingStrategySelectedStrategyState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { TradeOptionsPlayScoreWidget } from '../common/options-play-score-widget';
import { CompanyNameWidget } from './company-name-widget';

export interface ISingleTradeCompanyInformationWidgetProps {
  data: SingleTrade;
  isShared?: boolean;
  sharedBy?: string;
  creationDate?: Date;
  onClose: () => void;
}

export const SingleTradeCompanyInformationWidget: React.FC<ISingleTradeCompanyInformationWidgetProps> = ({
  data,
  isShared,
  sharedBy,
  creationDate: createdDate,
  onClose,
}: ISingleTradeCompanyInformationWidgetProps) => {
  const selectedCombinationId = useRecoilValue(tradingStrategySelectedStrategyState);
  const theme = useTheme();

  const index = parseInt(selectedCombinationId || '0');
  const isIncome = data.combination.isIncome;
  return (
    <Grid container>
      <CompanyNameWidget
        onClose={onClose}
        creationDate={createdDate}
        data={data}
        sharedBy={sharedBy}
        canRenderClose={true}
      />
      <Grid
        container
        justifyContent="space-between"
        alignItems={'center'}
        style={{
          backgroundColor: theme.palette.info.dark,
          borderRadius: theme.shape.borderRadius,
          paddingLeft: 1,
          paddingRight: 1,
        }}>
        <Grid item xs={isIncome ? 12 : 8}>
          <Typography variant="subtitle2" fontWeight="bold" p={1.5}>
            {data.combinationTitle}
          </Typography>
        </Grid>
        {!isIncome && (
          <Grid item xs={4}>
            <TradeOptionsPlayScoreWidget index={index} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
