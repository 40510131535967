import { CardContent, CardHeader, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { customizationState } from '@op/shared/src/states';
import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
export interface IBasicCardWidgetProps {
  sx?: SxProps<Theme>;
  title: ReactNode;
  subHeader?: ReactNode;
  content: ReactNode;
}

export const BasicCardWidget: React.FC<IBasicCardWidgetProps> = ({
  sx,
  title,
  subHeader,
  content,
}: IBasicCardWidgetProps) => {
  const customization = useRecoilValue(customizationState);
  const theme = useTheme();

  const getHeight = () => {
    if (customization && customization.isMerrill) {
      return 35;
    }
    return 50;
  };
  return (
    <>
      <CardHeader
        style={{
          height: getHeight(),
          backgroundColor: theme.palette.info.dark,
          width: '100%',
          borderRadius: theme.shape.borderRadius,
        }}
        title={
          //TODO: if string then wrap in typography else render children
          <>{title}</>
        }
        subheader={
          subHeader ? (
            <Typography variant="body1" textAlign="center">
              {subHeader}
            </Typography>
          ) : undefined
        }
      />
      <CardContent sx={sx}>{content}</CardContent>
    </>
  );
};
