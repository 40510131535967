import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Grid, Typography, useTheme } from '@mui/material';
import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';
import { useHubSentiment } from '@op/shared/src/hubs/use-hub-sentiment';
import { IChecklistItem, IData, OpScore, StrategyHelpers } from '@op/shared/src/models';
import Combination from '@op/shared/src/models/how/combination';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { configurationState, globalizationState, howDataState, whyDataState } from '@op/shared/src/states';
import { tradingRangeSimulatorState } from '@op/shared/src/states/how/how-states';
import { sentimentAtomFamily } from '@op/shared/src/states/sentiment-hub-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IconComponent } from '../common';
import { BasicCardWidget } from '../common/basic-card-widget';
import LocalizationContext from '../react-i18next/localization-context';
import { ScrollableBox } from '../styled';
import { StrategyChecklistPreviewWidget } from './strategy-checklist-preview';
import { GuideItem } from '../side-menu';

library.add(faCheckSquare, faExclamationTriangle, faTimesCircle);

export interface IStrategyChecklistProps extends IData<Combination> {
  shouldIncludeSentence?: boolean;
  checkListItems?: IChecklistItem[];
}

export const StrategyChecklistWidget: React.FC<IStrategyChecklistProps> = ({
  data,
  shouldIncludeSentence,
  checkListItems,
}: IStrategyChecklistProps) => {
  const combination = data;
  const whyData = useRecoilValue(whyDataState);
  const howData = useRecoilValue(howDataState);
  const configuration = useRecoilValue(configurationState);
  const marketSymbol = configuration?.marketIndexQuoteSymbol || '';
  useHubSentiment(marketSymbol);
  useHubSentiment(combination.symbol || '');
  const globalization = useRecoilValue(globalizationState);
  const marketTone = useRecoilValue<SentimentModel | undefined>(sentimentAtomFamily(marketSymbol));
  const hubSentiment = useRecoilValue<SentimentModel | undefined>(sentimentAtomFamily(combination.symbol || ''));
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const [powChecklistItem, setPowChecklistItem] = useState<IChecklistItem | undefined>();
  const [annualizedReturnChecklistItem, setAnnualizedReturnChecklistItem] = useState<IChecklistItem | undefined>();
  const [stockTrendChecklistItem, setStockTrendChecklistItem] = useState<IChecklistItem | undefined>();
  const [marketTrendChecklistItem, setMarketTrendChecklistItem] = useState<IChecklistItem | undefined>();
  const [earningsDateChecklistItem, setEarningsDateChecklistItem] = useState<IChecklistItem | undefined>();
  const [spreadAndLiquidityChecklistItem, setSpreadAndLiquidityChecklistItem] = useState<IChecklistItem | undefined>();
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();

  // checkListItems = [];

  useEffect(() => {
    const powItem = StrategyHelpers.getPOWChecklistItem(combination);
    setPowChecklistItem(powItem);
  }, [combination]);

  useEffect(() => {
    const annualizedItem = StrategyHelpers.getAnnualizedReturn(combination);
    setAnnualizedReturnChecklistItem(annualizedItem);
  }, [combination]);

  useEffect(() => {
    const stockItem = StrategyHelpers.getStockTrend(combination, hubSentiment, t);
    setStockTrendChecklistItem(stockItem);
  }, [combination, hubSentiment, globalization]);

  useEffect(() => {
    const marketTrendItem = StrategyHelpers.getMarketTrendChecklistItem(combination, marketTone, t);
    setMarketTrendChecklistItem(marketTrendItem);
  }, [combination, marketTone, globalization]);

  useEffect(() => {
    let earningsDate: Date | undefined;
    if (whyData && whyData.earningsDate && whyData.earningsDate.trim() !== '') {
      earningsDate = DateTimeHelper.resolveDate(whyData.earningsDate);
    }
    const earningsDateItem = StrategyHelpers.getEarningsDateChecklistItem(combination, earningsDate, t);
    setEarningsDateChecklistItem(earningsDateItem);
  }, [combination, whyData, globalization]);

  useEffect(() => {
    const spreadAndLiquidityItem = StrategyHelpers.getSpreadAndLiquidityChecklistItem(combination, t);
    setSpreadAndLiquidityChecklistItem(spreadAndLiquidityItem);
  }, [combination, globalization]);

  if (!configuration || !howData || !whyData || !tradingRangeSimulator) {
    return null;
  }

  const getOpScoreItem = () => {
    //TODO if (!ApplicationContext.configuration.disableOptionsPlayScorePanel) {
    if (!combination.quote.isTradeable || combination.isIncome || combination.hasOnlyStx()) {
      return undefined;
    }
    const outlookPercentBounds = tradingRangeSimulator.outlookPercentBoundsForPlChart(howData);
    const outlookPriceBounds = tradingRangeSimulator.outlookPriceBoundsForPlChart();
    const opScore = OpScore.fromData(combination, outlookPercentBounds, outlookPriceBounds);
    const score = opScore.score;
    const roundedScore = NumberFormatHelper.toIntString(score, 'never');
    const sentencePart = `${t('app.php.tradeTicket.optionsPlayScoreOf')} ${roundedScore}`;
    if (score < 80) {
      return {
        type: 'OpScore',
        title: t('app.php.common.titles.optionsPlayScore'),
        className: 'times-circle',
        sentence: `${sentencePart} ${t('app.php.tradeTicket.isLow')}`,
        color: 'red',
        sortOrder: 5,
      } as IChecklistItem;
    }
    if (score >= 80 && score < 100) {
      return {
        type: 'OpScore',
        title: t('app.php.common.titles.optionsPlayScore'),
        className: 'exclamation-triangle',
        sentence: `${sentencePart} ${t('app.php.tradeTicket.isGood')}`,
        color: '#ffad33',
        sortOrder: 5,
      } as IChecklistItem;
    }
    return {
      type: 'OpScore',
      title: t('app.php.common.titles.optionsPlayScore'),
      className: 'check-square',
      sentence: `${t('app.php.tradeTicket.excellentOptionsPlayScore')} ${roundedScore}.`,
      color: 'green',
      sortOrder: 5,
    } as IChecklistItem;
  };

  const renderIcon = (className: string, color: string) => {
    let imageSrc;
    let stroke;
    if (className === 'check-square') {
      imageSrc = 'SCGreen';
      stroke = theme.palette.success.main;
    } else if (className === 'exclamation-triangle') {
      imageSrc = 'SCWarning';
      stroke = theme.palette.warning.main;
    } else if (className === 'times-circle') {
      imageSrc = 'SCRed';
      stroke = theme.palette.error.main;
    }
    return <IconComponent name={imageSrc} stroke={stroke} />;
  };

  const renderChecklistItem = (item: IChecklistItem) => {
    if (shouldIncludeSentence) {
      return renderChecklistItemWithSentence(item);
    }
    return (
      <Grid item xs={12} container columnSpacing={1} alignItems={'center'}>
        <Grid item container xs={1.5}>
          {renderIcon(item.className, item.color)}
        </Grid>
        <Grid item xs={10.5}>
          <Typography variant="subtitle2">{item.title}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderChecklistItemWithSentence = (item: IChecklistItem) => {
    const textColor = item.type === 'SpreadAndLiquidity' && item.color === 'red' ? 'error.main' : 'inherit';
    return (
      <Grid item xs={12} container columnSpacing={1} alignItems={'center'}>
        <Grid item xs={1}>
          {renderIcon(item.className, item.color)}
        </Grid>
        <Grid item xs={11}>
          <Typography variant="subtitle2" fontWeight="bold">
            {item.title}
          </Typography>
          <Typography
            variant="subtitle2"
            color={textColor}
            dangerouslySetInnerHTML={{
              __html: `${item.sentence}`,
            }}></Typography>
        </Grid>
      </Grid>
    );
  };

  // const renderClassName = (checkListClass: string) => {
  //   if (checkListClass === 'check-square') {
  //     return faCheckSquare;
  //   } else if (checkListClass === 'times-circle') {
  //     return faTimesCircle;
  //   } else {
  //     return faExclamationTriangle;
  //   }
  // };

  const renderPow = () => {
    if (!powChecklistItem) {
      return null;
    }
    checkListItems.push(powChecklistItem);
    return renderChecklistItem(powChecklistItem);
  };

  const renderAnnualizedReturn = () => {
    if (!annualizedReturnChecklistItem) {
      return null;
    }
    checkListItems.push(annualizedReturnChecklistItem);
    return renderChecklistItem(annualizedReturnChecklistItem);
  };

  const renderStockTrend = () => {
    if (!stockTrendChecklistItem) {
      return null;
    }
    checkListItems.push(stockTrendChecklistItem);
    return renderChecklistItem(stockTrendChecklistItem);
  };

  const renderMarketTrend = () => {
    if (!marketTrendChecklistItem) {
      return null;
    }
    checkListItems.push(marketTrendChecklistItem);
    return renderChecklistItem(marketTrendChecklistItem);
  };

  const renderOptionsPlayScorePanel = () => {
    const scoreItem = getOpScoreItem();
    if (!scoreItem) {
      return null;
    }
    checkListItems.push(scoreItem);
    return renderChecklistItem(scoreItem);
  };

  const renderEarningsDateItem = () => {
    if (!earningsDateChecklistItem) {
      return null;
    }
    checkListItems.push(earningsDateChecklistItem);
    return renderChecklistItem(earningsDateChecklistItem);
  };

  const renderSpreadLiquidity = () => {
    if (!spreadAndLiquidityChecklistItem) {
      return null;
    }
    checkListItems.push(spreadAndLiquidityChecklistItem);
    return renderChecklistItem(spreadAndLiquidityChecklistItem);
  };

  const getChecklistTitle = () => {
    return (
      <Grid container>
        <GuideItem selector=".strategyAnalysis_helpPinPlaceholder" />
        <Grid item xs>
          <Typography variant="subtitle1" lineHeight={1.2} sx={{ textTransform: 'capitalize' }}>
            {t('app.php.common.titles.strategyChecklist')?.toString().toLowerCase()}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <StrategyChecklistPreviewWidget checkListItem={checkListItems} />
        </Grid>
      </Grid>
    );
  };

  const renderChecklistItems = () => {
    return (
      <ScrollableBox height={'350px'} sx={{ pb: 8 }}>
        <Grid container rowSpacing={1}>
          {renderPow()}
          {renderAnnualizedReturn()}
          {renderStockTrend()}
          {renderMarketTrend()}
          {renderOptionsPlayScorePanel()}
          {renderEarningsDateItem()}
          {renderSpreadLiquidity()}
        </Grid>
      </ScrollableBox>
    );
  };

  return <BasicCardWidget title={getChecklistTitle()} content={renderChecklistItems()} />;
};
