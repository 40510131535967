import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Theme, Typography } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import { isDataLoadingState, selectedSymbolState } from '@op/shared/src/states';
import {
  isOnGoesToThisPriceChangedAtom,
  tradingRangeSimulatorState,
  whatIfSimulatorState,
} from '@op/shared/src/states/how/how-states';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import {
  OptionsPlayCurrencyFormatInput,
  OptionsPlaySlider,
  OptionsPlayTextField,
  OptionsPlayThumbComponent,
} from '../styled';
import { PLSimulatorSubWidget } from './pl-simulator-sub-widget';
import { GuideItem } from '../side-menu';

//TODO: There is good amount of code duplicated with `Profit-And-Loss-Target-Widget`. This should be refactored.
export const WhatIfPriceWidget: React.FC = () => {
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [whatIfSimulator, setWhatIfSimulator] = useRecoilState(whatIfSimulatorState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const isDataLoading = useRecoilValue(isDataLoadingState);
  const setIsPriceChanged = useSetRecoilState(isOnGoesToThisPriceChangedAtom);
  const [price, setPrice] = useState(0);
  const [priceInputValue, setPriceInputValue] = useState('');
  const { t } = React.useContext(LocalizationContext);

  useEffect(() => {
    if (!whatIfSimulator) {
      return;
    }
    const cost = whatIfSimulator.whatIfSPrice;
    setPrice(cost);
    setPriceInputValue(cost.toFixed(2));
  }, [whatIfSimulator]);

  useEffect(() => {
    if (!isDataLoading) {
      setIsPriceChanged(false);
    }
  }, [isDataLoading]);

  const onSliderChangeCommittedForPrice = (value: number | number[]) => {
    if (!whatIfSimulator) {
      return;
    }
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    newWhatIfSimulator.whatIfSPrice = value as number;
    setWhatIfSimulator(newWhatIfSimulator);
    ApplicationContext.userActivityHub?.logActivity(
      'whatIfSPrice',
      'whatIfSPrice',
      `${value}`,
      'slidechange',
      selectedSymbol,
    );
  };

  const debouncedOnSliderChangeForPrice = useMemo(
    () => debounce(onSliderChangeCommittedForPrice, 50),
    [whatIfSimulator],
  );

  // WhatIfSimulator can be undefined, if the security does not have an option chain.
  if (!whatIfSimulator || !tradingRangeSimulator) {
    return null;
  }

  const max = tradingRangeSimulator.chartHighBound();
  const min = tradingRangeSimulator.chartLowBound();
  const mid = tradingRangeSimulator.chartMidStandardDeviation();
  const sds = tradingRangeSimulator.sds;

  const onSliderChangeForPrice = (_event: Event, value: number | number[], _activeThumb: number) => {
    setPriceInputValue((value as number).toFixed(2));
    setPrice(value as number);
    setIsPriceChanged(true);
    debouncedOnSliderChangeForPrice(value);
  };

  const onKeyPressOfPrice = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER' || price === undefined) {
      return;
    }
    let amount = price;
    if (price > max) {
      amount = max;
    }
    if (price < min) {
      amount = min;
    }
    setPrice(amount);
    setPriceInputValue((amount as number).toFixed(2));
    setIsPriceChanged(true);
    updateWhatIfPrice(amount);
    ApplicationContext.userActivityHub?.logActivity(
      'whatIfSPrice',
      'whatIfSPrice',
      `${amount}`,
      'blur',
      selectedSymbol,
    );
  };

  const updateWhatIfPrice = (price: number): void => {
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    newWhatIfSimulator.whatIfSPrice = price;
    setWhatIfSimulator(newWhatIfSimulator);
  };

  const renderPriceSlider = () => {
    //All from left.
    const sliderLength = max - min;
    const carretLength = mid - min;
    const thumbLength = price - min;
    const carretPercentage = (carretLength / sliderLength) * 100;
    const thumbPercentage = (thumbLength / sliderLength) * 100;

    const getBackgroundGradient = (theme: Theme) => {
      const defaultColor = theme.palette.slider.main; //'cyan';
      const errorColor = theme.palette.error.main; //'red';
      const successColor = theme.palette.success.main; //'green';
      if (thumbPercentage <= carretPercentage) {
        return `linear-gradient(90deg, ${defaultColor} ${thumbPercentage}%, ${errorColor} ${thumbPercentage}% ${carretPercentage}%, ${defaultColor} ${carretPercentage}%)`;
      }
      return `linear-gradient(90deg, ${defaultColor} ${carretPercentage}%, ${successColor} ${carretPercentage}% ${thumbPercentage}%, ${defaultColor} ${thumbPercentage}%)`;
    };

    return (
      <Box sx={{ position: 'relative' }}>
        <OptionsPlaySlider
          components={{ Thumb: OptionsPlayThumbComponent }}
          id="slide-whatIfSPrices"
          data-name="whatIfSPrice"
          data-value={whatIfSimulator.whatIfSPrice}
          value={price} //initial value of undefined is treaded as `Uncontrolled component`. Hence default zero value.
          onChange={onSliderChangeForPrice}
          min={min}
          max={max}
          step={0.01}
          marks={sds.map((sd) => {
            return { value: sd };
          })}
          valueLabelDisplay="off"
          track={false}
          sx={{
            '& .MuiSlider-rail': {
              height: 5,
              background: getBackgroundGradient,
            },
          }}
        />
        {/* NOTE: if there is no Standard deviations (SDS) no need to show the carret separtions. */}
        <KeyboardArrowUpIcon
          fontSize="small"
          onClick={() => onSliderChangeCommittedForPrice(sds[2])}
          sx={{
            display: tradingRangeSimulator.sds.length === 0 ? 'none' : 'inline-block',
            fontWeight: 'bold',
            position: 'absolute',
            top: '15px',
            left: `${carretPercentage}%`,
            transform: `translate(-50%, 0%)`,
            color: 'primary.main',
            cursor: 'pointer',
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <GuideItem selector=".priceAndDataSlider_helpPinPlaceholder" />
      <PLSimulatorSubWidget
        label={
          <Typography variant="body1">{t('app.php.how.toolbox.whatIfSimulator.labels.goesToThisPrice')}</Typography>
        }
        box={
          <OptionsPlayTextField
            variant="outlined"
            fullWidth
            value={priceInputValue}
            onChange={(event: any) => {
              setPrice(event.values.floatValue);
              setPriceInputValue(event.values.value);
            }}
            onKeyPress={(event) => onKeyPressOfPrice(event.key)}
            onBlur={() => onKeyPressOfPrice('ENTER')}
            name="whatIfPrice"
            className="op-formatted-textbox"
            id="what-if-price"
            InputProps={{
              inputComponent: OptionsPlayCurrencyFormatInput as any,
            }}
          />
        }
        leftLabel={
          <Typography variant="body1" sx={{ pr: 1 }}>
            {NumberFormatHelper.toCurrency(min)}
          </Typography>
        }
        slider={renderPriceSlider()}
        rightLabel={
          <Typography variant="body1" textAlign="right" sx={{ pl: 1 }}>
            {NumberFormatHelper.toCurrency(max)}
          </Typography>
        }
      />
    </>
  );
};
