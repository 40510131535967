import { LinkedPortfolio, LinkedWatchlist, Rule } from '@op/shared/src/models';
import React, { ReactNode, useEffect, useState } from 'react';
import { TradeIdeaLoaderWidget, WhatLoaderWidget } from './what-loader-widget';
import { EmptyRowWidget } from '../common';
import { Grid } from '@mui/material';
import { HeightWidget } from '../routes';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMinWidthBreakpoint } from '../states/use-min-width-breakpoint';
import { useWindowSize } from '../states/use-window-size';
import { GuideItem } from '../side-menu';

export interface IdeaProps<T> {
  data: T;
  isTechnicalIdea?: boolean;
  ideaType?: string;
}

export interface commonProps {
  symbol: string;
  companyName: string | undefined;
  type: string;
  signals: any | undefined;
  portfolios: LinkedPortfolio[];
  watchlists: LinkedWatchlist[];
}

//TODO: url - define interface for singals
export interface IRowProps extends commonProps {
  sentiment: string;
  rules: Rule[] | undefined;
}

export interface IReportProps extends commonProps {
  hasEarnings: string | undefined;
  daysToExpiry: string | undefined;
  url: string;
}

export interface ICreditSpreadsProps extends IReportProps {
  strategy: string;
  premiumPercentage: string | undefined;
  strategyType: 'Call' | 'Put';
  sellStrike: string;
  buyStrike: string;
}

export interface ICoveredCallsProps extends IReportProps {
  annualizedReturn: string | undefined;
  strike: string | undefined;
}

export interface IShortPutProps extends ICoveredCallsProps {}

export interface IRowCollection {
  data: (IRowProps | ICreditSpreadsProps | ICoveredCallsProps | IShortPutProps)[];
  getNode?: (data: IRowProps | ICreditSpreadsProps | ICoveredCallsProps | IShortPutProps) => ReactNode;
  itemsPerPage?: number;
}

/**
 * itemsPerPage is overriden in technical ideas as each card item is less more items can be loaded
 * To avoid loadmore function call by default
 */

export const Pager: React.FC<IRowCollection> = ({ data, getNode, itemsPerPage = 8 }: IRowCollection) => {
  const isNormalView = useMinWidthBreakpoint();
  const windowSize = useWindowSize();
  const containerHeight = isNormalView ? 600 : (windowSize?.height || 800) - 120;
  const [items, setItems] = useState(data || []);
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }
    setItems(data.slice(0, itemsPerPage));
    setHasNextPage(data.length > itemsPerPage);
  }, [data]);

  const loadMore = () => {
    if (!data || data.length === 0) {
      return;
    }
    const sliceLength = items.length + itemsPerPage;
    const slicedItems = data.slice(0, sliceLength);
    setItems(slicedItems);
    setHasNextPage(sliceLength <= data.length);
  };

  const loading = false;
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: false,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: `0px 0px ${containerHeight}px 0px`,
    delayInMs: undefined,
  });

  if (!data || !items) {
    return <WhatLoaderWidget />;
  }

  if (data.length === 0 || items.length === 0) {
    return <EmptyRowWidget message={'No Matching Symbols Found'} />;
  }

  return (
    <HeightWidget height={containerHeight} sx={{ pb: 6 }}>
      <div style={{ paddingLeft: '37%' }}>
        <GuideItem selector=".grid_companyName_helpPinPlaceholder" />
      </div>
      <div style={{ paddingRight: '10%', display: 'flex', justifyContent: 'flex-end' }}>
        <GuideItem selector=".grid_sentiment_helpPinPlaceholder" />
      </div>
      <div style={{ paddingRight: '25%', display: 'flex', justifyContent: 'flex-end' }}>
        <GuideItem selector=".grid_technicalRank_helpPinPlaceholder" />
      </div>
      <Grid container rowSpacing={1}>
        {items.map((idea, index) => {
          return (
            <Grid item xs={12} pr={0.5} key={`${idea.symbol}-${index}`}>
              {getNode && getNode(idea as IRowProps)}
            </Grid>
          );
        })}
        {hasNextPage && (
          <Grid item xs={12} ref={sentryRef}>
            <TradeIdeaLoaderWidget />
          </Grid>
        )}
      </Grid>
    </HeightWidget>
  );
};
