import FileCopyIcon from '@mui/icons-material/FileCopy';
import LinkIcon from '@mui/icons-material/Link';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React from 'react';

import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { selectedSymbolState } from '@op/shared/src/states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OPDialogWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
export interface LinkShareProps {
  onHandleClose: () => void;
  title: string;
  imgsrc: string;
  symbol: string;
  sentiment: any;
  screenshotid: any;
}

export const LinkShare: React.FC<LinkShareProps> = (props: LinkShareProps) => {
  const { onHandleClose, title, imgsrc, screenshotid } = props;
  const setNotifications = useSetRecoilState(notificationsState);
  const [loaded, setLoaded] = React.useState(false);
  const { t } = React.useContext(LocalizationContext);
  const value = title.substring(0, title.indexOf('@'));
  const imgUrl = `${window.location.origin}/screenshot/${screenshotid}`;
  const shareUrl = `${window.location.origin}/share/${screenshotid}`;
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  const CopyShareUrl = () => {
    // TODO : Create Common share genration link function
    navigator.clipboard.writeText(`${window.location.origin}/share/${screenshotid}`);
    setNotifications([{ type: 'success', content: 'Copied...!' }]);
    logActivity('BUTTON', 'copyShareUrl', shareUrl);
  };

  const CopyImgUrl = () => {
    navigator.clipboard.writeText(`${window.location.origin}/screenshot/${screenshotid}`);
    setNotifications([{ type: 'success', content: 'Copied...!' }]);
    logActivity('BUTTON', 'copyShareImageUrl', imgUrl);
  };
  const logActivity = (controlType: string, controlName: string, value?: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const renderDialogContent = () => {
    return (
      <div>
        {loaded ? null : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        )}
        <div style={loaded ? {} : { display: 'none' }}>
          <img src={imgsrc} width="100%" height="100%" onLoad={() => setLoaded(true)} />
          <br />
          <Grid container>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id="confirmPassword"
                label="ImageUrl"
                name="imgUrl"
                value={imgUrl}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Button onClick={CopyImgUrl} variant="contained" color="primary" startIcon={<FileCopyIcon />}>
                <Typography variant="button"> {t('app.php.modals.shareForms.shareClipboard.buttons.copy')}</Typography>
              </Button>
            </Grid>
          </Grid>{' '}
          &nbsp;
          <Grid container>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id="confirmPassword"
                label="ShareUrl"
                name="shareUrl"
                value={shareUrl}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Button onClick={CopyShareUrl} variant="contained" color="primary" startIcon={<FileCopyIcon />}>
                <Typography variant="button"> {t('app.php.modals.shareForms.shareClipboard.buttons.copy')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} sm={1} md={1}>
          <LinkIcon
            sx={{
              position: 'absolute',
              left: 8,
              top: 16,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1">Share: {value}</Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <OPDialogWidget
        open={true}
        onHandleClose={onHandleClose}
        dialogAction={undefined}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    </div>
  );
};
