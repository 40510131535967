import lodash from 'lodash';

export const unique = <T>(array: ArrayLike<T>): T[] => {
  return lodash.uniq(array);
};

export const isEqual = (value: any, other: any): boolean => {
  return lodash.isEqual(value, other);
};

export const orderByAscending = <T>(array: ArrayLike<T>, property: string): T[] => {
  return lodash.orderBy(array, [property as any], ['asc']);
};

export const orderByDescending = <T>(array: ArrayLike<T>, property: string): T[] => {
  return lodash.orderBy(array, [property as any], ['desc']);
};

export const downloadCsvFile = (csvData: ArrayBuffer, fileName: string): void => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
  URL.revokeObjectURL(url);
};

//todo: Make them function export
class helpers {
  objects = ['Arguments', 'Function', 'String', 'Date', 'RegExp', 'Array'];

  static isArguments = (data: any) => {
    const result = {}.toString.call(data) === '[object Arguments]' && !isNaN(data) && isFinite(data);
    return result;
  };

  static isFunction = (data: any) => {
    const result = Object.prototype.toString.call(data) === '[object Function]' && !isNaN(data) && isFinite(data);
    return result;
  };

  static isString = (data: any) => {
    return lodash.isString(data);
  };

  static isDate = (data: any) => {
    return lodash.isDate(data);
  };

  static isRegExp = (data: any) => {
    const result = Object.prototype.toString.call(data) === '[object RegExp]' && !isNaN(data) && isFinite(data);
    return result;
  };

  static isArray = (data: any) => {
    const result = Object.prototype.toString.call(data) === '[object Array]' && !isNaN(data) && isFinite(data);
    return result;
  };

  static isNumber = (data: any) => {
    return lodash.isNumber(data);
  };

  static uniqueArray = <T>(data: T[]) => {
    data = data || [];
    const set = new Set<T>(data);
    return Array.from(set);
  };

  static removeNullOrEmpty = (data: any[]) => {
    data = data || [];
    const result = data
      .map((p) => {
        return p && p.trim();
      })
      .filter(function (p) {
        return !!p;
      });
    return result;
  };

  static getParameterFromHash = (parameterName: string) => {
    const regExp = new RegExp('#.*[?&]?' + parameterName + '=([^&]+)(&|$)');
    const match = location.hash.match(regExp);
    const parameterValue = match ? match[1] : null;
    return parameterValue;
  };

  static extend = (
    Child: { prototype: { [x: string]: any; constructor: any }; superclass: any },
    Parent: { prototype: object | null },
  ) => {
    const originalPrototype = Child.prototype;
    Child.prototype = Object.create(Parent.prototype);
    for (let key in originalPrototype) {
      Child.prototype[key] = originalPrototype[key];
    }
    Child.prototype.constructor = Child;
    Child.superclass = Parent.prototype;
  };

  static isNullOrWhiteSpace = (str: string) => {
    return !str || /^\s*$/.test(str);
  };

  static arrayFromObject = (obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any }) => {
    const arr = [];
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        arr.push(obj[i]);
      }
    }
    return arr;
  };

  static groupBy = (list: string | any[], fn: (arg0: any) => any) => {
    // const groups = {};
    // for (let i = 0; i < list.length; i++) {
    //   let group = JSON.stringify(fn(list[i]));
    //   if (group in groups) {
    //     groups[group].push(list[i]);
    //   } else {
    //     groups[group] = [list[i]];
    //   }
    // }
    // return this.arrayFromObject(groups);
  };

  static getTestValueFunction = (value: { valueOf: () => any } | null) => {
    return function (el: { valueOf: () => any } | null) {
      return el === value || (el != null && value != null && el.valueOf() === value.valueOf());
    };
  };

  static find = (arr: any[], test: { call: (arg0: any, arg1: any, arg2: any, arg3: any) => any }, ctx?: any) => {
    let result = null;
    if (!helpers.isFunction(test)) {
      test = helpers.getTestValueFunction(test);
    }
    arr.some(function (el, i) {
      return test.call(ctx, el, i, arr) ? ((result = el), true) : false;
    });
    return result;
  };

  static findIndex = (arr: any[], test: any, ctx?: any) => {
    let result = -1;
    if (!helpers.isFunction(test)) {
      test = helpers.getTestValueFunction(test);
    }
    arr.some(function (el, i) {
      return test.call(ctx, el, i, arr) ? ((result = i), true) : false;
    });
    return result;
  };

  // #region Functional Extensions

  static not = (predicate: { apply: (arg0: any, arg1: IArguments) => any }) => {
    // return () => {
    //   return !predicate.apply(this, arguments);
    // };
  };

  static notNull = (arg: any) => {
    return arg != null && arg != undefined;
  };

  static deferredExecution = (
    action: { apply: (arg0: any, arg1: IArguments) => void },
    context?: undefined,
    delay?: undefined,
  ) => {
    return function () {
      const args = arguments;
      window.setTimeout(function () {
        action.apply(context, args);
      }, delay || 1);
    };
  };

  static partial = (fn: { apply: (arg0: any, arg1: any[]) => any } /*, args...*/) => {
    // const slice = Array.prototype.slice;
    // const args = slice.call(arguments, 1);
    // return () => {
    //   return fn.apply(this, args.concat(slice.call(arguments, 0)));
    // };  ---- priya
  };

  static partialRight = (fn: { apply: (arg0: any, arg1: any[]) => any } /*, args...*/) => {
    // const slice = Array.prototype.slice;
    // const args = slice.call(arguments, 1);
    // return () => {
    //   return fn.apply(this, slice.call(arguments, 0).concat(args));
    // }; --- priya
  };

  // vivek: moved from OptionChain.
  static getSecurityQuantity = (type?: string): number => {
    switch (type) {
      case 'M':
        return 10;
      case 'J':
        return 1000;
      case 'S':
      default:
        return 100;
    }
  };

  static toTitleCase = (text: string): string => {
    return text
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
  }
}

export default helpers;
