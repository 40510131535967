import { Grid, Typography } from '@mui/material';

import LocalizationContext from '../../../src/react-i18next/localization-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import React from 'react';
import { howWhyRanksDataState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';

export const WeekRangeWidget: React.FC = () => {
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const { t } = React.useContext(LocalizationContext);
  if (!howWhyRanksData.how) {
    return <>No quote data</>;
  }
  const quote = howWhyRanksData.how.quote;
  const week52Highfixed = NumberFormatHelper.toCurrency(quote.week52High && quote.week52High.toFixed(2));
  const week52Lowfixed = NumberFormatHelper.toCurrency(quote.week52Low && quote.week52Low.toFixed(2));

  return (
    <>
      <Grid item xs="auto">
        <Typography variant={'captionSmall'} >
          {t('app.php.why.labels.weekRange')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography component="div" variant={'captionSmall'}  textAlign="right">
          <strong>
            {week52Lowfixed} - {week52Highfixed}
          </strong>
        </Typography>
      </Grid>
    </>
  );
};
