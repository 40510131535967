import { Grid, Paper, Typography, useTheme } from '@mui/material';
import { ICreditSpreadsProps, Pager } from '../pager';
import React, { Suspense, useEffect, useState } from 'react';
import { Sorts, cloneDeep } from '@op/shared/src/models';
import {
  accountState,
  isDataLoadingState,
  reportSearchState,
  strategyShareDataState,
  subViewState,
  viewState,
} from '@op/shared/src/states';
import {
  creditSpreadDataCachedState,
  creditSpreadFilters,
  creditSpreadPortfolioPositionsCountState,
  creditSpreadState,
  creditSpreadWatchlistQuotesCountState,
  filtersCreditSpreadState,
  sortsCreditSpreadState,
} from '@op/shared/src/states/reports/credit-spread-states';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { CreditSpread } from './credit-spread';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { FilterContainer } from '../idea-filter-widget';
import { HasEarningsFilterWidget2 } from '../has-earnings-filter-widget';
import { ICreditSpreadReport } from '@op/shared/src/models/reports/credit-spread';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { IdeaDropDownWidget } from '../idea-dropdown-widget';
import { IdeaSecondRow } from '../idea-second-row';
import { IvRankFilterWidget2 } from '../iv-rank-filter-widget';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { OPBoldTypograpghy } from '../../styled/styled-components';
import { PremiumFilterWidget } from './premium-filter-widget';
import { SentimentFilterWidget2 } from '../sentiment-filter-widget';
import { ThirdRowContainer } from '../third-row-container';
import { ViewFiltersByCount2 } from '../../common';
import { WhatLoaderWidget } from '../what-loader-widget';
import { fetchSharedTradeByOccHash } from '@op/shared/src/services';
import formatting from '@op/shared/src/models/how/formatting';
import { getCreditSpreadReports } from '@op/shared/src/services/reports-service';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { types } from '@op/shared/src/models/enums/enums';
import { useFetchAndSetData } from '../../states/use-fetch-and-set-data';
import { useNavigateSearch } from '../../states/use-navigate-search';
import { useSearchParams } from 'react-router-dom';

export const CreditSpreads: React.FC = () => {
  const fetchAndSetData = useFetchAndSetData();
  const navigateSearch = useNavigateSearch();
  const resetSearchText = useResetRecoilState(reportSearchState);
  const [creditSpreads, setCreditSpreads] = useRecoilState(creditSpreadState);
  const resetCreditSpreads = useResetRecoilState(creditSpreadDataCachedState);
  const [creditSpreadSorts, setCreditSpreadSorts] = useRecoilState(sortsCreditSpreadState);
  const [cpFilters, setCpFilters] = useRecoilState(filtersCreditSpreadState);
  const linkedPortfoliosCount = useRecoilValue(creditSpreadPortfolioPositionsCountState);
  const linkedWatchListsCount = useRecoilValue(creditSpreadWatchlistQuotesCountState);
  const [items, setItems] = useState<ICreditSpreadsProps[] | undefined>();
  const [searchParams] = useSearchParams();
  const setNotifications = useSetRecoilState(notificationsState);
  const setStrategyShareData = useSetRecoilState(strategyShareDataState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const account = useRecoilValue(accountState);
  const theme = useTheme();

  const logActivity = (symbol: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      'selectedCreditSpreadSymbol',
      'selectedCreditSpreadSymbol',
      symbol,
      'click',
      symbol,
    );
  };

  const onItemSelected = async (symbol: string, hash: string) => {
    if (!hash || hash.trim() === '') {
      return;
    }
    if (isDataLoading) {
      return;
    }
    setIsDataLoading(true);
    const tradeResponse = await fetchSharedTradeByOccHash(hash);
    if (
      !tradeResponse ||
      tradeResponse.hasErrors ||
      !tradeResponse.data ||
      symbol.trim().toUpperCase() !== tradeResponse.data.symbol.trim().toUpperCase()
    ) {
      setIsDataLoading(false);
      setNotifications([{ type: 'error', content: 'Incorrect hash information' }]);
      return;
    }
    const data = tradeResponse.data as any;
    const isSuccessful = await fetchAndSetData(data.symbol);
    if (!isSuccessful) {
      setIsDataLoading(false);
      setNotifications([{ type: 'error', content: 'Insufficent data' }]);
      return;
    }
    data.targetDate = formatting.getCurrentDate();
    setStrategyShareData(data);
    setIsDataLoading(false);
    setView('trade');
    setSubView('expand');
    logActivity(symbol);
    navigateSearch({ symbol: data.symbol, hash: hash, type: data.combinationType });
  };

  const processUrl = async () => {
    const symbol = searchParams.get('symbol');
    const hash = searchParams.get('hash');
    await onItemSelected(symbol, hash);
  };

  const mapItems = (data: ICreditSpreadReport[] | undefined) => {
    if (!data) {
      return [];
    }
    const reports = data.map((t) => {
      return {
        symbol: t.symbol,
        companyName: t.companyName,
        type: 'creditSpread',
        strategy: t.strategy,
        premiumPercentage: NumberFormatHelper.toPercentage(
          NumberFormatHelper.roundPercentageValue(t.premium.percentage),
        ),
        hasEarnings: t.earningsDate ? 'Y' : 'N',
        daysToExpiry: String(DateTimeHelper.daysFromNow(new Date(t.expiry))),
        signals: t.signals,
        portfolios: t.portfolios || [],
        watchlists: t.watchlists || [],
        url: t.url,
        strategyType: t.type,
        sellStrike: NumberFormatHelper.roundNumber(t.strike.sell).toString(),
        buyStrike: NumberFormatHelper.roundNumber(t.strike.buy).toString(),
      } as ICreditSpreadsProps;
    });
    return reports;
  };

  useEffect(() => {
    (async () => {
      await processUrl();
    })();
    return () => {
      resetSearchText();
    };
  }, []);

  useEffect(() => {
    if (creditSpreads && creditSpreads.data) {
      setItems(mapItems(creditSpreads.data));
      return;
    }
    (async () => {
      const response = await getCreditSpreadReports();
      if (response.hasErrors || !response.data) {
        setItems([]);
        setNotifications([{ type: 'error', content: 'Unable to get credit spreads reports.' }]);
        return;
      }
      setCreditSpreads(response.data);
      setItems(mapItems(response.data.data));
    })();
  }, [creditSpreads]);

  if (!creditSpreads || !account) {
    return <WhatLoaderWidget />;
  }

  const type = types.find((f) => f.name === 'creditSpread');

  const sentimentFilter = cpFilters.find((g) => g.name === 'sentiment');
  const hasEarningFilter = cpFilters.find((g) => g.name === 'hasEarnings');
  const premiumFilter = cpFilters.find((g) => g.name === 'premium');
  const ivRankFilter = cpFilters.find((g) => g.name === 'ivRank');

  const isAllFiltersSelected = () => {
    let clone = cloneDeep(cpFilters);
    clone = clone.filter((c) => c.name !== 'linkedPortfolios' && c.name !== 'linkedWatchLists');
    const isAllSelectedExceptSentiment = clone
      .filter((g) => g.name !== 'sentiment')
      .flatMap((g) => g.filters.map((f) => f.selected))
      .every((s) => s === true);
    const sentimentFilter = clone.find((g) => g.name === 'sentiment');
    return isAllSelectedExceptSentiment && sentimentFilter.filters.find((f) => f.name === 'all')?.selected;
  };

  const onFilterClick = (group: IFilterGroup) => {
    const clone = cloneDeep(cpFilters);
    let group1 = clone.find((g) => g.name === group.name);
    if (!group1) {
      return;
    }
    const filtered = clone.filter((g) => g.name !== group.name);
    filtered.push(group);
    setCpFilters(filtered);
  };

  const onSelectAllFilters = () => {
    const groups = cloneDeep(cpFilters);
    const clone = groups.filter((c) => c.name !== 'linkedPortfolios' && c.name !== 'linkedWatchLists');
    for (const group of clone) {
      for (const filter of group.filters) {
        filter.selected = true;
        if (group.name === 'sentiment') {
          filter.selected = filter.name === 'all';
        }
      }
    }
    setCpFilters(groups);
  };

  const onResetFilters = () => {
    const groups = cloneDeep(creditSpreadFilters);
    setCpFilters(groups);
  };

  const getFilter = (name: 'linkedPortfolios' | 'linkedWatchLists', count: number) => {
    const f = cpFilters.find((g) => g.name === name);
    const clone = cloneDeep(f);
    if (clone.filters.length === 0) {
      return;
    }
    clone.filters[0].title = count.toString();
    return clone;
  };

  const renderInformation = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ py: 0.5, textAlign: 'justify' }}>
            Find the highest yielding opportunities to sell credit spreads with our proprietary scanning technology.
            Leveraging our technical directional strategies, liquidity metrics and high yielding credit spreads all in a
            single report to deliver high quality actionable trade setups for credit spread traders.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <OPBoldTypograpghy component={'span'} pr={0.5}>
            Step 1:
          </OPBoldTypograpghy>
          <Typography component={'span'}>Analyze Credit Spreads for Report.</Typography>
        </Grid>
        <Grid item xs={12}>
          <OPBoldTypograpghy pr={0.5} component={'span'}>
            Step 2:
          </OPBoldTypograpghy>
          <Typography component={'span'}>Size trade based on Portfolio Size.</Typography>
        </Grid>
        <Grid item xs={12}>
          <OPBoldTypograpghy component={'span'} pr={0.5}>
            Step 3:
          </OPBoldTypograpghy>
          <Typography component={'span'}>Manage Trades using OptionsPlay Portfolio.</Typography>
        </Grid>
        <OPBoldTypograpghy py={0.5}>{`Last Updated on: ${DateTimeHelper.getReportFormattedDate(creditSpreads.created)}`}</OPBoldTypograpghy>
      </Grid>
    );
  };

  return (
    <Suspense fallback={<WhatLoaderWidget />}>
      <Grid container>
        <Grid item xs={12} py={1}>
          <Paper sx={{ height: '100%', border: '1px solid transparent', borderRadius: 2 }} elevation={4}>
            <Grid container xs={12} rowSpacing={1} p={1}>
              <IdeaDropDownWidget count={creditSpreads.creditSpreadCount} ideaType={type} />
              <IdeaSecondRow
                onSort={(sorts: Sorts) => setCreditSpreadSorts(sorts)}
                selectedSorts={creditSpreadSorts}
                defaultSortOptions={Sorts.CreditSpreadDefaultSorts}
                Filters={
                  <FilterContainer
                    isSelectedAll={isAllFiltersSelected()}
                    onSelectAll={onSelectAllFilters}
                    onReset={onResetFilters}>
                    <SentimentFilterWidget2 filter={sentimentFilter} onClick={onFilterClick} />
                    <HasEarningsFilterWidget2 filter={hasEarningFilter} onClick={onFilterClick} />
                    <PremiumFilterWidget filter={premiumFilter} onClick={onFilterClick} />
                    <IvRankFilterWidget2 filter={ivRankFilter} onClick={onFilterClick} />
                  </FilterContainer>
                }
              />
              <ThirdRowContainer
                ideaType={type.name}
                createdOn={creditSpreads.created}
                title="Credit Spread Opportunity"
                data={renderInformation()}
                refreshReport={resetCreditSpreads}>
                {account.canWorkWithPortfolios && (
                  <ViewFiltersByCount2
                    filter={getFilter('linkedPortfolios', linkedPortfoliosCount)}
                    onClick={onFilterClick}
                    icon="personalPortfolioIcon"
                    stroke={linkedPortfoliosCount ? theme.palette.primary.main : '#999999'}
                  />
                )}
                {account.canWorkWithWatchLists && (
                  <ViewFiltersByCount2
                    filter={getFilter('linkedWatchLists', linkedWatchListsCount)}
                    onClick={onFilterClick}
                    icon="personalWatchlistIcon"
                    stroke={linkedWatchListsCount ? theme.palette.primary.main : '#999999'}
                  />
                )}
              </ThirdRowContainer>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Pager
            data={items}
            getNode={(idea) => <CreditSpread onItemSelected={onItemSelected} data={idea as ICreditSpreadsProps} />}
          />
        </Grid>
      </Grid>
    </Suspense>
  );
};
