import ApplicationContext from '@op/shared/src/models/how/application-context';
import { getSecurityModel } from '@op/shared/src/services';
import { accessTokenKey, accountState, refreshTokenKey } from '@op/shared/src/states';
import { useMatch } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { useOPNavigate } from './use-navigate-search';

export const useFetchAndSetAccount = () => {
  const opNavigate = useOPNavigate();
  const setAccount = useSetRecoilState(accountState);
  const resetAccount = useResetRecoilState(accountState);
  const resetRoute = useMatch('/password/reset');
  const shareRoute = useMatch('/share/*');

  const fetchAndSetAccount = async () => {
    let accessToken: string | undefined;
    try {
      accessToken = window.localStorage.getItem(accessTokenKey) || undefined;
    } catch (e) {
      console.error(e);
    }
    ApplicationContext.accessToken = accessToken as string;
    const account = await getSecurityModel(accessToken);
    if (account) {
      setAccount(account);
      return true;
    }
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
    if (!shareRoute && !resetRoute) {
      opNavigate('/login');
    }
    ApplicationContext.accessToken = undefined;
    ApplicationContext.refreshToken = undefined;
    resetAccount();
    return false;
  };

  return fetchAndSetAccount;
};
