import { Grid, Typography, useTheme } from '@mui/material';
import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';
import { SentimentType } from '@op/shared/src/models/what/sentiment-type';
import { accountState, globalizationState, isHubInitiatedState, selectedSymbolState } from '@op/shared/src/states';
import {
  fetchSentiments,
  sentimentAtomFamily,
  sentimentsUpdater,
  subscribeToSentiments,
  unsubscribeFromSentiments,
} from '@op/shared/src/states/sentiment-hub-states';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { IconComponent } from './icons';
import { RedGreenWidget } from './red-green-widget';
export interface ISentimentShortTermWidgetProps {
  symbol: string;
  isIconOnly?: boolean;
  isQuoteBar?: boolean;
}

export const SentimentShortTermWidget: React.FC<ISentimentShortTermWidgetProps> = ({
  symbol,
  isIconOnly,
  isQuoteBar,
}: ISentimentShortTermWidgetProps) => {
  const hubSentiment = useRecoilValue<SentimentModel | undefined>(sentimentAtomFamily(symbol));
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  const isHubInitiated = useRecoilValue(isHubInitiatedState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [delay, setDelay] = useState(2000);
  const globalization = useRecoilValue(globalizationState);
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ref, inView, entry] = useInView({
    threshold: 1,
    delay: delay,
  });
  const account = useRecoilValue(accountState);
  const { t } = React.useContext(LocalizationContext);

  const fetchSentiment = async () => {
    const fetchedSentiments = await fetchSentiments([symbol]);
    if (!fetchedSentiments || fetchedSentiments.length === 0) {
      return;
    }
    setSentiments(fetchedSentiments);
  };

  React.useEffect(() => {
    if (!isHubInitiated || !symbol || !inView) {
      return;
    }
    (async () => {
      // setDelay(5000);
      if (!hubSentiment) {
        await fetchSentiment();
      }
      await subscribeToSentiments([symbol]);
    })();
  }, [isHubInitiated, inView]);

  useEffect(() => {
    if (inView || !hubSentiment) {
      return;
    }
    //If symbol is selected and loaded in other panels like quotebar, how panel etc. we should not unsubscribe the quotes.
    if (selectedSymbol?.toLowerCase() === hubSentiment.symbol.toLowerCase()) {
      return;
    }
    (async () => {
      await unsubscribeFromSentiments([symbol]);
    })();
  }, [inView, hubSentiment]);

  if (account === null) {
    return <></>;
  }
  if (!globalization || !globalization.active) {
    return;
  }
  const renderShortTermSentiment = () => {
    if (isIconOnly) {
      return undefined;
    }
    const shortTermSentiment = hubSentiment?.syrahShortSentiment;
    if (!shortTermSentiment) {
      return undefined;
    }

    const textStyle =
      globalization.french && isQuoteBar && shortTermSentiment.name.length <= 7
        ? 10
        : globalization.french && isQuoteBar && shortTermSentiment.name.length > 7
        ? 8
        : isQuoteBar && shortTermSentiment.name.length > 7
        ? 8
        : undefined;
    const getTextbyStyle = () => {
      return isQuoteBar ? (
        <Typography variant="captionSmall" sx={{ pr: 1 }} style={{ fontSize: textStyle }}>
          1M
        </Typography>
      ) : (
        <Typography variant="body1" sx={{ pr: 1 }}>
          {t('app.php.header.js.sentimentModel.sentimentShort')}
        </Typography>
      );
    };

    const getTextByScreenSize = () => {
      return (
        <Typography variant={isQuoteBar ? 'captionSmall' : 'body1'} style={{ fontSize: textStyle }}>
          <RedGreenWidget value={shortTermSentiment.name}>
            <b>{t(`app.php.common.labels.${shortTermSentiment.name}`)}</b>
          </RedGreenWidget>
        </Typography>
      );
    };
    return (
      <Grid container title={shortTermSentiment.secondary} style={{ flexDirection: 'row', width: '100%' }}>
        <Grid item>{getTextbyStyle()}</Grid>
        <Grid item>{getTextByScreenSize()}</Grid>
      </Grid>
    );
  };

  const renderSentimentIcon = () => {
    if (!isIconOnly) {
      return undefined;
    }
    const shortTermSentiment = hubSentiment?.syrahShortSentiment;
    if (!shortTermSentiment) {
      return undefined;
    }
    switch (shortTermSentiment.name.toUpperCase()) {
      case SentimentType.BEARISH:
      case SentimentType.MILDLYBEARISH:
        return <IconComponent name="trendingDown" size={12} fill={theme.palette.error.main} />;
      case SentimentType.BULLISH:
      case SentimentType.MILDLYBULLISH:
        return <IconComponent name="trendingUp" size={12} fill={theme.palette.success.main} />;
      case SentimentType.NEUTRAL:
        return <IconComponent name="trendingNeutral" size={12} fill={theme.palette.warning.main} />;
      default:
        return <></>;
    }
  };

  return (
    <span ref={ref}>
      {renderShortTermSentiment()}
      {renderSentimentIcon()}
    </span>
  );
};
