import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { IData, IncomeCombination, PortfolioManagementCombination } from '@op/shared/src/models';
import Combination from '@op/shared/src/models/how/combination';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { incomeCombinationSelectedIdState } from '@op/shared/src/states';
import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { RedGreenWidget } from '../common/red-green-widget';
import LocalizationContext from '../react-i18next/localization-context';

export const OptionsPlayKeyValueWidget: React.FC<{ label: ReactNode; value: ReactNode }> = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => {
  return (
    <Grid container justifyContent="space-between" columnSpacing={1} textAlign="right">
      <Grid item xs={6}>
        {label}
      </Grid>
      <Grid item xs={6}>
        {value}
      </Grid>
    </Grid>
  );
};

export interface IStrategyDataWidgetProps extends IData<Combination | IncomeCombination> {}

export const StrategyDataWidget: React.FC<IStrategyDataWidgetProps> = ({ data }: IStrategyDataWidgetProps) => {
  const incomeCombinationSelectedId = useRecoilValue(incomeCombinationSelectedIdState);
  const { t } = React.useContext(LocalizationContext);
  const cost = NumberFormatHelper.toCurrency(data.costWithoutOwned());
  const maxReward = data.maxReward();
  const maxRisk = data.maxRisk();
  const pop = data.probabilityOfProfitDisplayed();
  let pow = '';
  if (data.isIncome) {
    pow = NumberFormatHelper.toPercentage(data.worthlessProb() * 100) || '';
  }

  if (!data) {
    return null;
  }

  const daysToExpiry = () => {
    const daysToexpiry = data.daysToExpiry();
    if (daysToexpiry === -999999) {
      return 'N/A';
    } else {
      return daysToexpiry;
    }
  };

  // const toFixed = (value: string | number) => {
  //   return NumberFormatHelper.toCurrency(value);
  // };  //changes

  const renderSentiment = () => {
    let sentiment = data.sentiment();
    if (!data.isPortfolio) {
      return null;
    }
    return (
      <OptionsPlayKeyValueWidget
        label={<Typography variant="body1">{t('app.php.how.howPanel.labels.sentiment')}</Typography>}
        value={<RedGreenWidget value={sentiment}>{sentiment}</RedGreenWidget>}
      />
    );
  };

  const renderCost = () => {
    if (data.isPortfolio) {
      return null;
    }
    return (
      <OptionsPlayKeyValueWidget
        label={
          <>
            <div className="risk_panel_cost_step" />
            <Typography variant="body1" className="cost_step">
              {t('app.php.how.howPanel.labels.cost')}
            </Typography>
          </>
        }
        value={
          <Typography variant="body1" id="cost" fontWeight="bold">
            {cost}
          </Typography>
        }
      />
    );
  };

  const renderAnnualisedReturn = () => {
    if (!data.isIncome) {
      return null;
    }

    const renderAnnualizedReturnWithHelpText = () => {
      const tooltipTitle =
        (daysToExpiry() as number) < 60
          ? `This annualized return is being calculated over a time frame of only ${daysToExpiry()} days.`
          : undefined;
      if (tooltipTitle === undefined) {
        return (
          <Typography variant="body1" id="annualized-return" fontWeight="bold" component="span">
            {`${NumberFormatHelper.toPercentage(data.annualizedReturn(), 'never')}${!tooltipTitle ? '' : '*'}`}
          </Typography>
        );
      }
      return (
        <Tooltip
          placement="left"
          title={
            <Typography variant="body1" color="inherit">
              {tooltipTitle}
            </Typography>
          }
          arrow>
          <Typography variant="body1" id="annualized-return" fontWeight="bold" component="span">
            {`${NumberFormatHelper.toPercentage(data.annualizedReturn(), 'never')}${!tooltipTitle ? '' : '*'}`}
          </Typography>
        </Tooltip>
      );
    };

    return (
      <OptionsPlayKeyValueWidget
        label={<Typography variant="body1">{t('app.php.how.howPanel.labels.annualizedReturn')}</Typography>}
        value={
          <Box>
            {renderAnnualizedReturnWithHelpText()}/
            <Typography variant="body1" component="span" fontWeight="bold">
              {NumberFormatHelper.toPercentage((data as IncomeCombination).rawReturn(), 'never')}
            </Typography>
          </Box>
        }
      />
    );
  };

  const render12MonthProjectedYield = () => {
    //Do not show this if not income or own shares.
    const hasShares = incomeCombinationSelectedId === 1;
    if (!data.isIncome || hasShares) {
      //TODO: should check for data.IsNakedPut()
      return null;
    }

    const renderAnnualizedReturnWithHelpText = () => {
      const tooltipTitle =
        (daysToExpiry() as number) < 60
          ? `This annualized return is being calculated over a time frame of only ${daysToExpiry()} days.`
          : undefined;
      return (
        <Tooltip
          placement="left"
          title={
            <Typography variant="body1" color="inherit">
              {tooltipTitle || ''}
            </Typography>
          }
          arrow>
          <Typography variant="body1" id="annualized-return" fontWeight="bold" component="span">
            {`${NumberFormatHelper.toPercentage((data as IncomeCombination).twelveMonthProjectedDividendYield())}${
              !tooltipTitle ? '' : '*'
            }`}
          </Typography>
        </Tooltip>
      );
    };

    return (
      <OptionsPlayKeyValueWidget
        label={<Typography variant="body1">{t('app.php.how.howPanel.labels.12MProjectedYield')}</Typography>}
        value={<Box>{renderAnnualizedReturnWithHelpText()}</Box>}
      />
    );
  };

  const renderPoporPow = () => {
    if (data.isIncome) {
      return (
        <OptionsPlayKeyValueWidget
          label={
            <>
              {/* <div className="risk_panel_pow_step" /> */}
              <Typography variant="body1" whiteSpace="normal">
                {t('app.php.how.howPanel.labels.pow')}
              </Typography>
            </>
          }
          value={
            <Typography variant="body1" id="pow" fontWeight="bold">
              {pow}
            </Typography>
          }
        />
      );
    }
    return (
      <OptionsPlayKeyValueWidget
        label={
          <>
            {/* <div className="risk_panel_pop_step" /> */}
            <Typography
              variant="body1"
              style={{
                whiteSpace: 'nowrap',
                overflowWrap: 'break-word',

                fontWeight: 400,
              }}>
              {t('app.php.how.howPanel.labels.pop')}
            </Typography>
          </>
        }
        value={
          <Typography variant="body1" id="pop" fontWeight="bold">
            {pop}
          </Typography>
        }
      />
    );
  };

  const renderResultingCost = () => {
    if (!data.isPortfolio) {
      return null;
    }
    if (data.isResulting) {
      let adjustedCostBasis = (data as PortfolioManagementCombination).adjustedCostBasis();
      return (
        <OptionsPlayKeyValueWidget
          label={<Typography variant="body1">{t('app.php.how.howPanel.labels.adjustedCostBasis')}</Typography>}
          value={
            <Typography variant="body1" fontWeight="bold" id="adj-cost">
              {NumberFormatHelper.toCurrency(adjustedCostBasis)}
            </Typography>
          }
        />
      );
    } else return null;
  };

  const renderMaxReward = () => {
    return (
      <>
        <OptionsPlayKeyValueWidget
          label={
            <>
              <div className="risk_panel_max_reward_step" />
              <Typography variant="body1" className={'maximum_reward_step'}>
                {t('app.php.how.howPanel.labels.maxReward')}
              </Typography>
            </>
          }
          value={
            <Typography variant="body1" fontWeight="bold" id="max-reward">
              {NumberFormatHelper.toCurrency(maxReward, 'never')}
            </Typography>
          }
        />
      </>
    );
  };

  const renderMaxRisk = () => {
    return (
      <OptionsPlayKeyValueWidget
        label={
          <>
            <div className="risk_panel_max_risk_step" />
            <Typography variant="body1" className={'maximum_risk_step'}>
              {t('app.php.how.howPanel.labels.maxRisk')}
            </Typography>
          </>
        }
        value={
          <Typography variant="body1" fontWeight="bold" id="max-risk">
            {NumberFormatHelper.toCurrency(maxRisk, 'never')}
          </Typography>
        }
      />
    );
  };

  const renderBreakEvens = () => {
    return (
      <OptionsPlayKeyValueWidget
        label={
          <>
            <div className="risk_panel_breakeven_step" />
            <Typography variant="body1" className={'breakeven_step'}>
              {t('app.php.how.howPanel.labels.breakeven')}
            </Typography>
          </>
        }
        value={
          <Typography variant="body1" fontWeight="bold" id="breakeven">
            {data.breakevenDisplayed()}
          </Typography>
        }
      />
    );
  };

  const renderDaysToExpiry = () => {
    return (
      <OptionsPlayKeyValueWidget
        label={
          <>
            <div className="risk_panel_days_to_expiry_step" />
            <Typography variant="body1" className={'days_to_expiry_step'} style={{ flexWrap: 'nowrap' }}>
              {t('app.php.how.howPanel.labels.daysToExpiry')}
            </Typography>
          </>
        }
        value={
          <Typography variant="body1" fontWeight="bold" id="days-expiry">
            {daysToExpiry()}
          </Typography>
        }
      />
    );
  };

  return (
    <Grid container>
      <Grid item container justifyContent="space-between" xs={12} style={{ paddingLeft: '2px' }}>
        {renderSentiment()}
        {renderCost()}
        {renderResultingCost()}
        {renderMaxReward()}
        {renderMaxRisk()}
        {renderAnnualisedReturn()}
        {render12MonthProjectedYield()}
        {renderPoporPow()}
        {renderBreakEvens()}
        {renderDaysToExpiry()}
      </Grid>
    </Grid>
  );
};
