import { Typography } from '@mui/material';
import { customizationState } from '@op/shared/src/states/customization-states';
import React from 'react';
import { useRecoilValue } from 'recoil';

export interface ICombinationHeadingWidgetProps {
  text: string;
  uncoveredIncomeWarning?: string;
  coveredCallAlternativeIncomeFoundWarning?: string;
  coveredCallDefaultIncomeFound?: boolean;
}

export const CombinationHeadingWidget: React.FC<ICombinationHeadingWidgetProps> = ({
  text,
  uncoveredIncomeWarning,
  coveredCallAlternativeIncomeFoundWarning,
  coveredCallDefaultIncomeFound,
}: ICombinationHeadingWidgetProps) => {
  const customization = useRecoilValue(customizationState);

  if (!customization) {
    return null;
  }

  const renderCoveredCallDefaultIncomeFound = () => {
    if (!coveredCallDefaultIncomeFound) {
      return undefined;
    }
    return (
      <span className="default-icon">
        <i className="fa fa-check" aria-hidden="true"></i>
      </span>
    );
  };

  const renderCoveredCallAlternativeIncomeFoundWarning = () => {
    if (!coveredCallAlternativeIncomeFoundWarning || coveredCallAlternativeIncomeFoundWarning.length === 0) {
      return undefined;
    }

    return (
      <span className="alternative-icon" role="text" aria-label={coveredCallAlternativeIncomeFoundWarning}>
        <img src="Content/images/icons/warning-icon.png" className="warning-icon" alt="" />
      </span>
    );
  };

  const renderUncoveredIncomeWarning = () => {
    if (!uncoveredIncomeWarning || uncoveredIncomeWarning.length === 0) {
      return undefined;
    }

    return (
      <span role="text" aria-label={uncoveredIncomeWarning}>
        *
      </span>
    );
  };

  const renderTitle = () => {
    const larger = customization.isEmbeddingPlatform ? '0.730rem' : '0.813rem';
    const fontStyle = {
      fontSize: text.length > 45 ? '0.625rem' : larger,
    };
    return (
      <Typography textAlign="center" lineHeight={1.2} style={fontStyle}>
        {text}
      </Typography>
    );
  };

  return (
    <>
      {renderTitle()}
      {renderCoveredCallDefaultIncomeFound()}
      {renderCoveredCallAlternativeIncomeFoundWarning()}
      {renderUncoveredIncomeWarning()}
    </>
  );
};
