import { Button, CardHeader, Grid, MenuItem, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import LocalizationContext from '../react-i18next/localization-context';

interface IOPSelectDropDownHeaderProps {
  onClearFilter?: () => void;
  header?: string;
  type: string;
}
type StyledDropdownItemProps = {
  color: 'green' | 'blue' | 'default';
  selected?: boolean;
  isWatchList?: boolean;
};

export const StyledDropdownItemWithBorder = styled(MenuItem)<StyledDropdownItemProps>(
  ({ color, selected, isWatchList, theme }) => ({
    fontWeight: selected ? '600' : 'inherit',
    fontSize: 10,
    // ':hover': {
    //   backgroundColor: !isWatchList && theme.palette.secondary.light,
    //   fontWeight: isWatchList && '600',
    //   '& .count-value .global-count-value': {
    //     backgroundColor: theme.palette.grey[500],
    //     color: theme.palette.text.primary,
    //   },
    //   '& .global-count-value': {
    //     backgroundColor: theme.palette.grey[500],
    //     color: '#ffffff', //theme.palette.text.primary,
    //   },
    //   '& .person-count-value': {
    //     backgroundColor: theme.palette.grey[500],
    //     color: '#ffffff', //theme.palette.text.primary,
    //   },
    // },

    '& .label-value': {
      whiteSpace: 'noWrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: 2,
    },
    '& .global-label-value': {
      whiteSpace: 'noWrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: selected ? theme.palette.success.main : theme.palette.text.primary,
      paddingRight: 2,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    '& .local-label-value': {
      whiteSpace: 'noWrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: selected ? theme.palette.primary.main : theme.palette.text.primary,
      paddingRight: 2,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    '& .count-value': {
      border: selected ? `1px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.grey[500]}`,
      backgroundColor:
        selected && color === 'green'
          ? theme.palette.success.main
          : selected && color === 'blue'
          ? theme.palette.primary.main
          : 'inherit',
      color: selected ? '#ffffff' : 'inherit',
      borderRadius: theme.shape.borderRadius,
      textAlign: 'center',
    },
    '& .global-count-value': {
      border: selected ? `1px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.grey[500]}`,
      backgroundColor: selected ? theme.palette.success.main : 'inherit',
      color: selected ? (theme.palette.mode === 'light' ? '#ffffff' : '#000000') : 'inherit',
      borderRadius: theme.shape.borderRadius,
      textAlign: 'center',
    },
    '& .person-count-value': {
      border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
      backgroundColor: selected ? theme.palette.primary.main : 'inherit',
      color: selected ? (theme.palette.mode === 'light' ? '#ffffff' : '#000000') : 'inherit',
      borderRadius: theme.shape.borderRadius,
      textAlign: 'center',
    },
  }),
);

export const OPSelectDropDownHeader: React.FC<IOPSelectDropDownHeaderProps> = ({
  onClearFilter,
  header,
  type,
}: IOPSelectDropDownHeaderProps) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const typesForHideClearButton = ['sort', 'sectors', 'scans', 'filters', 'tradeTicket', 'strategy'];

  const getType = () => {
    if (type === 'alert') {
      return 'Alerts By';
    }
    if (type === 'notification') {
      return 'Notifications By';
    }
    if (type === 'sort') {
      return 'Sort By';
    }
    if (type === 'sectors') {
      return t('app.php.what.tradeIdeas.js.defaultSector');
    }
    if (type === 'scans') {
      return t('app.php.what.tradeIdeas.js.defaultReason');
    }
    if (type === 'filters') {
      return 'Filters';
    }
    if (type === 'tradeTicket') {
      return 'Select Portfolio';
    }
    if (type === 'strategy') {
      return header;
    }
    return '';
  };
  return (
    <CardHeader
      title={
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Grid item p={1}>
            <Typography sx={{ fontSize: 10 }}>{getType()}</Typography>
          </Grid>
          {typesForHideClearButton.includes(type) ? null : (
            <Grid item>
              <Button
                variant="outlined"
                style={{ backgroundColor: theme.palette.background.paper }}
                size="small"
                onClick={onClearFilter}>
                Clear
              </Button>
            </Grid>
          )}
        </Grid>
      }
      sx={{
        backgroundColor: theme.palette.primary.light,
        pl: 1,
        pr: 1,
        pt: 0.5,
        pb: 0.5,
        borderRadius: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: `1px solid ${theme.palette.secondary.dark}`,
      }}
    />
  );
};
