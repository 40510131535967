import { Grid, Typography, useTheme } from '@mui/material';
import { Quote } from '@op/shared/src/models';
import formatting from '@op/shared/src/models/how/formatting';
import React from 'react';
import { FlagWidget, IconComponent, SentimentShortTermWidget } from '../common';
import { OpLinkMenus } from '../ideas/op-link-menu';
import { useRecoilValue } from 'recoil';
import { customizationState } from '@op/shared/src/states';

interface Props {
  quote: Quote;
  onClickPinUnpin: () => void;
  onClickTradeIdea: () => void;
}
export const FirstRow: React.FC<Props> = ({ quote, onClickPinUnpin, onClickTradeIdea }: Props) => {
  const theme = useTheme();
  const customization = useRecoilValue(customizationState);

  const renderPortfolioIdeaCount = () => {
    if (!customization || !customization.showTechnicalReports) {
      return;
    }
    return (
      <>
        {quote.isTradeIdea && (
          <Grid item xs={'auto'} onClick={onClickTradeIdea} className="js-trade-idea-icon">
            <IconComponent name="tradeHover" stroke={theme.palette.info.light} size={20} />
          </Grid>
        )}
        <Grid item xs={'auto'}>
          <OpLinkMenus
            data={quote.portfolios.map((p) => {
              return {
                id: p.id,
                symbol: p.symbol,
                name: p.name,
              };
            })}
            title="Available in Portfolio"
            noTitle="No Portfolios under this symbol"
            icon="personalPortfolioIcon"
            hoverIcon="hoverPersonalPortfolioIcon"
            filterType="portfolio"
            stroke={theme.palette.primary.main}
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={5} container>
        <Grid item xs={8}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            lineHeight={1}
            className="js-trade-idea-symbol-dot-exchange-symbol">
            {formatting.symbolDotExchangeToSymbol(quote.symbol)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FlagWidget exchange={formatting.getExchange(quote.symbol)} variant="square" />
        </Grid>
      </Grid>
      <Grid item xs={7} container justifyContent={'flex-end'} alignItems={'center'} columnSpacing={0.5} pr={0.5}>
        {renderPortfolioIdeaCount()}
        {quote.isPinned && (
          <Grid item xs={'auto'} onClick={onClickPinUnpin}>
            <IconComponent name="pinActive" stroke={theme.palette.info.light} size={15} />
          </Grid>
        )}
        <Grid item xs={1}>
          <SentimentShortTermWidget symbol={quote.symbol} isIconOnly />
        </Grid>
      </Grid>
    </Grid>
  );
};
