import { Avatar, FormControl, Grid, IconButton, MenuItem, Select, useTheme } from '@mui/material';
import editIcon from '@op/shared/assets/images/editIcon.svg';
import Us from '@op/shared/assets/images/us.png';
import { SubscriptionPaymentForms } from '@op/shared/src/models';
import { SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import {
  SubscriptionPaymentFormsState,
  currentSubscriptionScreenState,
  currentSubscriptionState,
} from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconComponent } from '../../common';
import LocalizationContext from '../../react-i18next/localization-context';
import { OptionsPlayTextField } from '../../styled/options-play-components';
import { OPBoldTypograpghy, OPSemiBoldTypograpghy } from '../../styled/styled-components';
import { Province, countries, states } from './data-list';

interface IBillingInfoWidgetProps {
  errors: SubscriptionPaymentForms;
  setErrors: (v: SubscriptionPaymentForms) => void;
}

export const BillingInfoWidget: React.FC<IBillingInfoWidgetProps> = ({
  errors,
  setErrors,
}: IBillingInfoWidgetProps) => {
  const [cardData, setcardData] = useRecoilState(SubscriptionPaymentFormsState);
  const CurrentSubscriptionData = useRecoilValue(currentSubscriptionState);
  const currentSubscriptionScreen = useRecoilValue(currentSubscriptionScreenState);
  const theme = useTheme();
  const [billEdit, setBillEdit] = React.useState(false);
  const { t } = React.useContext(LocalizationContext);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState(true);

  useEffect(() => {
    if (!CurrentSubscriptionData || !CurrentSubscriptionData.currentSubscription.billingInfo) {
      return;
    }
    const billingAddress = CurrentSubscriptionData?.currentSubscription?.billingInfo?.billingAddress;
    setcardData({
      ...cardData,
      address: billingAddress.address,
      country: billingAddress.country,
      city: billingAddress.city,
      state: billingAddress.state,
      postalCode: billingAddress.zip,
    });
    setErrors({
      ...errors,
      address: '',
      country: '',
      city: '',
      state: '',
      postalCode: '',
    });
  }, [billEdit]);

  if (!CurrentSubscriptionData) {
    return null;
  }

  const subscriptionstatus = CurrentSubscriptionData.currentSubscription?.subscriptionStatus;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.name === 'postalCode' && event.target.value.length > 10) {
      return;
    }
    if (event.target.name === 'country') {
      let newCardData = { ...cardData };
      newCardData.state = '';
      setcardData({ ...newCardData, [event.target.name]: event.target.value });
      setErrors({ ...errors, [event.target.name]: '' });
      return;
    }

    setcardData({ ...cardData, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    const phoneRegex = /^\+?[1-9]\d{5,15}$/;
    setIsValidPhoneNumber(phoneRegex.test(phoneNumber));
    setPhoneNumber(value);
  };
  const renderPhoneTypeView = () => {
    return (
      <FormControl style={{ minWidth: 10 }}>
        <Select
          variant="standard"
          disableUnderline
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={10}>
          <MenuItem value={10}>
            <IconComponent name="mobileWithWatches" stroke="black" />
          </MenuItem>
          <MenuItem value={20}>
            <IconComponent name="HomelandIcon" stroke="black" />
          </MenuItem>
          <MenuItem value={30}>
            <IconComponent name="OfficelandIcon" stroke="black" />
          </MenuItem>
        </Select>
      </FormControl>
    );
  };
  const renderMobileNumberView = () => {
    if (currentSubscriptionScreen === 'changeInformation') {
      return null;
    }
    return (
      <Grid item xs={12} container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.phoneNumber')}</OPSemiBoldTypograpghy>
        </Grid>
        <Grid item container xs={9}>
          <Grid item container justifyContent="space-between" xs={3}>
            <Grid
              item
              xs={5}
              justifyContent="center"
              alignItems="center"
              container
              sx={{
                backgroundColor: theme.palette.primary.light,
                height: 35,
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
              }}>
              <Avatar alt="flag" src={Us} style={{ width: 25, height: 25 }} />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={6}
              sx={{ backgroundColor: theme.palette.primary.light, height: 35 }}>
              {renderPhoneTypeView()}
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <OptionsPlayTextField
              variant="outlined"
              name="phoneNumber"
              size="small"
              type="number"
              fullWidth
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={!isValidPhoneNumber}
              helperText={!isValidPhoneNumber && 'Please enter a valid phone number.'}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderAddressFields = () => {
    return (
      <Grid container rowSpacing={2}>
        {/* <Grid item xs={12} container alignItems="center">
          {renderMobileNumberView()}
        </Grid> */}
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.address')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item container rowGap={1} xs={9}>
            <OptionsPlayTextField
              variant="outlined"
              id="address"
              name="address"
              value={cardData.address}
              onChange={handleChange}
              helperText={errors.address}
              error={errors.address && errors.address !== '' ? true : false}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.country')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={9}>
            <OptionsPlayTextField
              id="outlined-select-currency"
              select
              size="small"
              fullWidth
              name="country"
              value={cardData.country}
              onChange={handleChange}
              variant="outlined">
              {countries.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </OptionsPlayTextField>
          </Grid>
        </Grid>
        <Grid item container xs={12} columnSpacing={1} justifyContent="space-between" alignItems="center">
          <Grid item container xs={6} justifyContent="space-between" alignItems="center">
            <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.city')}</OPSemiBoldTypograpghy>
            <Grid item container xs={6}>
              <OptionsPlayTextField
                variant="outlined"
                id="city"
                name="city"
                onChange={handleChange}
                helperText={errors.city}
                value={cardData.city}
                error={errors.city && errors.city !== '' ? true : false}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item container xs={5} justifyContent="space-between" alignItems="center">
            <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.state')}</OPSemiBoldTypograpghy>
            <Grid item xs={9}>
              {cardData.country === 'US' ? (
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="state"
                  value={cardData.state}
                  fullWidth
                  onChange={handleChange}
                  helperText={errors.state}
                  error={errors.state && cardData.state === '' ? true : false}
                  variant="outlined">
                  {states.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              ) : cardData.country === 'CA' ? (
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="state"
                  value={cardData.state}
                  fullWidth
                  onChange={handleChange}
                  helperText={errors.state}
                  error={errors.state && cardData.state === '' ? true : false}
                  variant="outlined">
                  {Province.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              ) : (
                <OptionsPlayTextField
                  variant="outlined"
                  id="state"
                  name="state"
                  value={cardData.state}
                  onChange={handleChange}
                  helperText={errors.state}
                  error={errors.state && cardData.state === '' ? true : false}
                  size="small"
                  fullWidth
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} container justifyContent="space-between" alignItems="center">
          <Grid item xs={6} container>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('app.php.subscriptions.zipCode')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={6} container>
            <OptionsPlayTextField
              variant="outlined"
              name="postalCode"
              value={cardData.postalCode}
              id="postalCode"
              helperText={errors.postalCode}
              error={errors.postalCode && cardData.postalCode === '' ? true : false}
              onChange={handleChange}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSavedAddress = () => {
    const address = CurrentSubscriptionData.currentSubscription?.billingInfo?.billingAddress?.address + ', ' || '';
    const country = CurrentSubscriptionData.currentSubscription?.billingInfo?.billingAddress?.country + ', ' || '';
    const city = CurrentSubscriptionData.currentSubscription?.billingInfo?.billingAddress?.city + ', ' || '';
    const state = CurrentSubscriptionData.currentSubscription?.billingInfo?.billingAddress?.state + ', ' || '';
    const zip = CurrentSubscriptionData.currentSubscription?.billingInfo?.billingAddress?.zip || '';
    const totalAddress = address + country + city + state + zip;
    return (
      <Grid container>
        <Grid container item xs={12} sx={{ backgroundColor: theme.palette.mode === 'light' ? '#f9f9f9' : '#262D32' }}>
          <Grid item xs={10} justifyContent="center" alignItems="center" p={1}>
            <OPBoldTypograpghy>{totalAddress}</OPBoldTypograpghy>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={6}>
              <IconButton
                onClick={() => {
                  setBillEdit(!billEdit);
                }}>
                <img src={editIcon} alt="" style={{ fill: theme.palette.text.secondary }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {billEdit && (
            <Grid container>
              <Grid item xs={12} my={2}>
                <OPBoldTypograpghy
                  style={{ fontSize: 14, color: theme.palette.mode === 'light' ? '#666666' : '#999999' }}>
                  {t('app.php.subscriptions.billingAddressEdit')}
                </OPBoldTypograpghy>
              </Grid>
              <Grid xs={12} item>
                {renderAddressFields()}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  return subscriptionstatus === SubscriptionStatus.ACTIVE_TRIAL ||
    subscriptionstatus === SubscriptionStatus.EXPIRED_TRIAL ||
    subscriptionstatus === SubscriptionStatus.EXPIRED_EXTENDED_TRIAL
    ? renderAddressFields()
    : renderSavedAddress();
};
