import CachedIcon from '@mui/icons-material/Cached';
import { Grid, Paper, useTheme } from '@mui/material';
import { IIdea, Sorts, cloneDeep, isGlobal } from '@op/shared/src/models';
import { tradeSimulatorOperationType, types } from '@op/shared/src/models/enums/enums';
import helpers from '@op/shared/src/models/how/helpers';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { fetchTradeIdeas } from '@op/shared/src/services';
import {
  accountState,
  customizationState,
  isDataLoadingState,
  sortsTradesState,
  subViewState,
  tradeSimulatorOperationTypeState,
  tradesState,
  viewState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import {
  filtersTradesNewState,
  prepareScans,
  prepareSectors,
  reportSearchState,
  scanFilterState1,
  sectorFilterState1,
  technicalIdeaFilters,
  tradePortfolioPositionsCountState,
  tradeWatchlistQuotesCountState,
  tradesCachedState,
} from '@op/shared/src/states/trades/trades-states';
import React, { Suspense, useEffect, useState } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ViewFiltersByCount2 } from '../../common';
import { GuideItem } from '../../side-menu';
import { useFetchAndSetData } from '../../states/use-fetch-and-set-data';
import { useNavigateSearch } from '../../states/use-navigate-search';
import { OPIconButton } from '../../styled';
import { IdeaDropDownWidget } from '../idea-dropdown-widget';
import { FilterContainer } from '../idea-filter-widget';
import { IdeaSecondRow } from '../idea-second-row';
import { IvRankFilterWidget2 } from '../iv-rank-filter-widget';
import { LiquidityRatingFilterWidget2 } from '../liquidity-rating-filter-widget';
import { IRowProps, Pager } from '../pager';
import { SentimentFilterWidget2 } from '../sentiment-filter-widget';
import { WhatLoaderWidget } from '../what-loader-widget';
import { MarketCapitalFilterWidget } from './market-capital-filter-widget';
import { ScansFilterWidget2 } from './scans-filter-widget';
import { SectorsFilterWidget2 } from './sectors-filter-widget';
import { TechnicalIdea } from './technical-idea';

export const TechnicalIdeas: React.FC = () => {
  const resetSearchText = useResetRecoilState(reportSearchState);
  const [trades, setTrades] = useRecoilState(tradesState);
  const resetTradeIdeas = useResetRecoilState(tradesCachedState);
  const [ideaFilters, setIdeaFilters] = useRecoilState(filtersTradesNewState);
  const [sectorFilter1, setSectorFilter] = useRecoilState(sectorFilterState1);
  const [scanFilter, setScanFilter] = useRecoilState(scanFilterState1);
  const [tradeSorts, setTradeSorts] = useRecoilState(sortsTradesState);
  const linkedPortfoliosCount = useRecoilValue(tradePortfolioPositionsCountState);
  const linkedWatchListsCount = useRecoilValue(tradeWatchlistQuotesCountState);
  const customization = useRecoilValue(customizationState);
  const setNotifications = useSetRecoilState(notificationsState);
  const account = useRecoilValue(accountState);
  const theme = useTheme();
  const shareRoute = useMatch('/share/*');
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const fetchAndSetData = useFetchAndSetData();
  const [view, setView] = useRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const navigateSearch = useNavigateSearch();
  const [searchParams] = useSearchParams();
  const [items, setItems] = useState<IRowProps[] | undefined>();

  const onItemSelected = async (symbol: string) => {
    if (!symbol || symbol.trim() === '') {
      return;
    }
    if (isDataLoading) {
      return;
    }
    if (shareRoute) {
      navigateSearch({ symbol: symbol }, '/idea/technical-idea');
      return;
    }
    setIsDataLoading(true);
    const isSuccessful = await fetchAndSetData(symbol);
    if (isSuccessful) {
      setSubView(undefined);
      setView(view);
      setSimulatorOperation(
        view === 'income' ? tradeSimulatorOperationType.OptionsGrid : tradeSimulatorOperationType.PLSimulator,
      );
      // logActivity(symbol);
    }
    navigateSearch({ symbol: symbol }, '/idea/technical-idea');
    setIsDataLoading(false);
  };

  // const logActivity = (symbol: string) => {
  //   ApplicationContext.userActivityHub?.logActivity(
  //     'selectedTradeIdeaSymbol',
  //     'selectedTradeIdeaSymbol',
  //     symbol,
  //     'click',
  //     symbol,
  //   );
  // };

  const processUrl = async () => {
    const symbol = searchParams.get('symbol');
    await onItemSelected(symbol);
  };

  const mapItems = (data: IIdea[]) => {
    if (!data) {
      return [];
    }
    const reports = data.map((t) => {
      return {
        ...t,
        type: 'trade',
        companyName: helpers.toTitleCase(t.companyName),
        premiumPercentage: undefined,
      } as IRowProps;
    });
    return reports;
  };

  useEffect(() => {
    (async () => {
      await processUrl();
    })();
    return () => {
      resetSearchText();
    };
  }, []);

  useEffect(() => {
    if (trades && trades.data) {
      setItems(mapItems(trades.data));
      return;
    }
    if (!trades) {
      (async () => {
        const fetchedTrades = await fetchTradeIdeas(false);
        if (!fetchedTrades) {
          setItems([]);
          setNotifications([{ type: 'error', content: 'Unable to get technical ideas.' }]);
          return;
        }
        const sectorFilterGroup = prepareSectors(fetchedTrades.sectors);
        const scanFilterGroup = prepareScans(fetchedTrades.scans);
        setSectorFilter(sectorFilterGroup);
        setScanFilter(scanFilterGroup);
        setTrades(fetchedTrades);
        setItems(mapItems(fetchedTrades.data));
      })();
    }
  }, [trades]);

  /*Technical-Ideas can be rendered for anonymous users. Hence do not add !account check here.*/
  if (!trades) {
    return <WhatLoaderWidget />;
  }

  const type = types.find((f) => f.name === 'technicalIdea');
  const sentimentFilter = ideaFilters.find((g) => g.name === 'sentiment');
  const marketCapFilter = ideaFilters.find((g) => g.name === 'marketCap');
  const ivRankFilter = ideaFilters.find((g) => g.name === 'ivRank');
  const liquidityFilter = ideaFilters.find((g) => g.name === 'liquidity');

  const isAllFiltersSelected = () => {
    let clone = cloneDeep(ideaFilters);
    clone = clone.filter((c) => c.name !== 'linkedPortfolios' && c.name !== 'linkedWatchLists');
    const allSelectedExceptSentiment = clone
      .filter((g) => g.name !== 'sentiment')
      .flatMap((g) => g.filters.map((f) => f.selected))
      .every((s) => s === true);
    const sentimentSelected = clone.find((g) => g.name === 'sentiment').filters.find((f) => f.name === 'all')?.selected;
    const allSectorSelected = sectorFilter1 && sectorFilter1.filters.find((s) => s.name === 'allSectors')?.selected;
    const allScanSelected = scanFilter && scanFilter.filters.every((s) => s.selected);
    return allSelectedExceptSentiment && sentimentSelected && allSectorSelected && allScanSelected;
  };

  const onFilterClick = (group: IFilterGroup) => {
    const clone = cloneDeep(ideaFilters);
    let group1 = clone.find((g) => g.name === group.name);
    if (!group1) {
      return;
    }
    const filtered = clone.filter((g) => g.name !== group.name);
    filtered.push(group);
    setIdeaFilters(filtered);
  };

  const onSelectAllFilters = () => {
    const groups = cloneDeep(ideaFilters);
    const clone = groups.filter((c) => c.name !== 'linkedPortfolios' && c.name !== 'linkedWatchLists');
    for (const group of clone) {
      for (const filter of group.filters) {
        filter.selected = true;
        if (group.name === 'sentiment') {
          filter.selected = filter.name === 'all';
        }
      }
    }
    const cloneSectorFilter = cloneDeep(sectorFilter1);
    for (const filter of cloneSectorFilter.filters) {
      filter.selected = filter.name === 'allSectors';
    }
    const cloneScanFilter = cloneDeep(scanFilter);
    for (const filter of cloneScanFilter.filters) {
      filter.selected = true;
    }
    setSectorFilter(cloneSectorFilter);
    setScanFilter(cloneScanFilter);
    setIdeaFilters(groups);
  };

  const onResetFilters = () => {
    const groups = cloneDeep(technicalIdeaFilters);
    const cloneSectorFilter = cloneDeep(sectorFilter1);
    for (const filter of cloneSectorFilter.filters) {
      filter.selected = filter.name === 'allSectors';
    }
    const cloneScanFilter = cloneDeep(scanFilter);
    for (const filter of cloneScanFilter.filters) {
      filter.selected = true;
    }
    setSectorFilter(cloneSectorFilter);
    setScanFilter(cloneScanFilter);
    setIdeaFilters(groups);
  };

  const getFilter = (name: 'linkedPortfolios' | 'linkedWatchLists', count: number) => {
    const f = ideaFilters.find((g) => g.name === name);
    const clone = cloneDeep(f);
    if (clone.filters.length === 0) {
      return;
    }
    clone.filters[0].title = count.toString();
    return clone;
  };

  const renderPortfolioWatchListFilterCount = () => {
    if (!customization || !customization.showTechnicalReports) {
      return;
    }
    return (
      <>
        {account?.canWorkWithPortfolios && (
          <Grid item xs={3.2}>
            <ViewFiltersByCount2
              filter={getFilter('linkedPortfolios', linkedPortfoliosCount)}
              onClick={onFilterClick}
              icon="personalPortfolioIcon"
              stroke={linkedPortfoliosCount ? theme.palette.primary.main : '#999999'}
            />
          </Grid>
        )}
        {account?.canWorkWithWatchLists && (
          <Grid item xs={3.2}>
            <ViewFiltersByCount2
              filter={getFilter('linkedWatchLists', linkedWatchListsCount)}
              onClick={onFilterClick}
              icon="personalWatchlistIcon"
              stroke={linkedWatchListsCount ? theme.palette.primary.main : '#999999'}
            />
          </Grid>
        )}
      </>
    );
  };

  const renderIVRankView = () => {
    if (isGlobal()) {
      return;
    }
    return <IvRankFilterWidget2 filter={ivRankFilter} onClick={onFilterClick} />;
  };

  const renderLiquidityRatingView = () => {
    if (isGlobal()) {
      return;
    }
    return <LiquidityRatingFilterWidget2 filter={liquidityFilter} onClick={onFilterClick} isTechnicalIdea />;
  };

  return (
    <Suspense fallback={<WhatLoaderWidget />}>
      <Grid container>
        <Grid item xs={12} py={1}>
          <Paper sx={{ height: '100%', border: '1px solid transparent', borderRadius: 2 }} elevation={4}>
            <Grid container item xs={12} rowSpacing={1} p={1} mt={0}>
              <GuideItem selector=".tradeIdeas_helpPinPlaceholder" />
              <IdeaDropDownWidget count={trades.totalIdeas} ideaType={type} />
              <IdeaSecondRow
                onSort={(s) => setTradeSorts(s)}
                selectedSorts={tradeSorts}
                defaultSortOptions={Sorts.TradeIdeasDefaultSorts}
                Filters={
                  <FilterContainer
                    isSelectedAll={isAllFiltersSelected()}
                    onSelectAll={onSelectAllFilters}
                    onReset={onResetFilters}>
                    <SentimentFilterWidget2 filter={sentimentFilter} onClick={onFilterClick} />
                    <MarketCapitalFilterWidget filter={marketCapFilter} onClick={onFilterClick} />
                    {renderIVRankView()}
                    {renderLiquidityRatingView()}
                    <SectorsFilterWidget2 />
                    <ScansFilterWidget2 />
                  </FilterContainer>
                }
              />
              <Grid container item xs={12}>
                {renderPortfolioWatchListFilterCount()}
                <Grid item xs={4}>
                  <GuideItem selector=".sentimentFilterButtons_helpPinPlaceholder" />
                  <SentimentFilterWidget2 filter={sentimentFilter} onClick={onFilterClick} onlyIcon />
                </Grid>
                <Grid item xs={1.6}>
                  <OPIconButton id="js-technical-ideas-refresh-icon" onClick={() => resetTradeIdeas()}>
                    <CachedIcon sx={{ color: theme.palette.info.light }} fontSize={'medium'} />
                  </OPIconButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Pager
            data={items}
            itemsPerPage={10}
            getNode={(idea) => <TechnicalIdea data={idea as IRowProps} onItemSelected={onItemSelected} />}
          />
        </Grid>
      </Grid>
    </Suspense>
  );
};
