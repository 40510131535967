import TwitterIcon from '@mui/icons-material/Twitter';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { OPDialogWidget } from '../common';
export interface TwitterShareProps {
  onHandleClose: () => void;
  title: string;
  imgsrc: string;
  symbol: string;
  sentiment: any;
}

export const TwitterShare: React.FC<TwitterShareProps> = (props: TwitterShareProps) => {
  const { onHandleClose, title, imgsrc, symbol, sentiment } = props;
  const value = title.substring(0, title.indexOf('@'));
  const sentimentvalue = sentiment.charAt(0).toUpperCase() + sentiment.slice(1);
  const textareaTiltle = `${sentimentvalue} $${symbol} Trade: ${title}`;
  const [loaded, setLoaded] = React.useState(false);
  const renderDialogContent = () => {
    return (
      <div>
        <div>
          {loaded ? null : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          )}
          <img
            style={loaded ? {} : { display: 'none' }}
            src={imgsrc}
            width="100%"
            height="100%"
            onLoad={() => setLoaded(true)}
          />
        </div>
        <br />
        <Grid container direction="row">
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              multiline
              rows={5}
              defaultValue={textareaTiltle}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderDialogAction = () => {
    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item>
          <Button autoFocus onClick={onHandleClose} variant="contained" color="primary">
            <Typography variant="button"> Share on Twitter</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} sm={1} md={1}>
          <TwitterIcon
            sx={{
              position: 'absolute',
              left: 8,
              top: 16,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1">Share: {value}</Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <OPDialogWidget
        open={true}
        onHandleClose={onHandleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    </div>
  );
};
