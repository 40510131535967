export enum EmbedderEventType {
  SetSymbol = 'widget:setSymbol',
  SymbolChanged = 'widget:symbolChanged',
  TradeTicketExecute = 'strategies:tradeTicketExecute',
  SetTheme = 'widget:setTheme',
  SetLocale = 'widget:setLocaleValue',
  SetQuote = 'widget:setQuoteValue',
  SetCommissions = 'widget:setCommissions',
}

export enum ImplementationType {
  DEFAULT = 'Default',
  MERRILL = 'Merrill',
  NASDAQ = 'Nasdaq',
  SOGOTRADE = 'SogoTrade',
  FIRSTRADE = 'FirsTrade',
  BNC = 'Bnc'
}

export enum Sentiment {
  ALL = 'All',
  BULLISH = 'Bullish',
  BEARISH = 'Bearish',
  NEUTRAL = 'Neutral',
}

export enum CombinationSentiment {
  SHARP_MOVE = 'sharp-move',
  BEARISH = 'bearish',
  BULLISH = 'bullish',
  NEUTRAL = 'neutral',
}

export enum BuyOrSell {
  BUY = 'Buy',
  SELL = 'Sell',
}

export enum LongOrShort {
  LONG = 'Long',
  SHORT = 'Short',
}

export enum LegType {
  CALL = 'Call',
  PUT = 'Put',
  SECURITY = 'Security',
}

export enum OptionType {
  CALL = 'Call',
  PUT = 'Put',
}

export enum PhpHubEvents {
  QUOTES = 'Quotes',
  SYRAH_SENTIMENT = 'SyrahSentiment',
  POSITION_DATA = 'PositionData',
  LATEST_HISTORICAL_DATA = 'LatestHistoricalData',
  MARKET_WORK_TIME = 'MarketWorkTime',
  MARKET_INDEX_SENTIMENT = 'MarketIndexSentiment',
  PORTFOLIO_ALERTS = 'PortfolioAlerts',
  KILL_SESSION = 'KillSession',
}

export enum Role {
  Admin = 'Admin',
  PromoUser = 'PromoUser',
  ViewOPI = 'ViewOPI',
  Sharing = 'Sharing',
  ViewOPP = 'ViewOPP',
  WidgetsUser = 'WidgetsUser',
  WidgetsEmbedder = 'WidgetsEmbedder',
  SingleSignOnIdP = 'SingleSignOnIdP',
  CustomerSupport = 'CustomerSupport',
  PlaidAccess = 'PlaidAccess',
}

export enum Permissions {
  ALLOW_SHARING = 'AllowSharing',
  EDIT_USERS = 'EditUsers',
  MANAGE_API_KEYS = 'ManageApiKeys',
  MANAGE_COMMISSIONS = 'ManageCommissions',
  MANAGE_DISCOUNTS = 'ManageDiscounts',
  MANAGE_GLOBAL_B2_B_WATCH_LISTS = 'ManageGlobalB2BWatchLists',
  MANAGE_GLOBAL_B2_C_WATCH_LISTS = 'ManageGlobalB2CWatchLists',
  MANAGE_OWN_ENTITLEMENTS = 'ManageOwnEntitlements',
  MANAGE_OWN_PORTFOLIO = 'ManageOwnPortfolio',
  MANAGE_PORTFOLIO_EMAIL_DELIVERY = 'ManagePortfolioEmailDelivery',
  MANAGE_PAYMENT_TRANSACTIONS = 'ManagePaymentTransactions',
  MANAGE_PROFILE = 'ManageProfile',
  ALLOW_CHANGE_PASSWORD = 'AllowChangePassword',
  MANAGE_PROFILE_SUBSCRIPTIONS = 'ManageProfileSubscriptions',
  MANAGE_PROMO_CODES = 'ManagePromoCodes',
  MANAGE_SUBSCRIPTIONS = 'ManageSubscriptions',
  MANAGE_COVERED_CALL_SETTINGS = 'ManageCoveredCallSettings',
  NOT_AUTHENTICATED = 'NotAuthenticated',
  USE_REAL_TIME_QUOTES = 'UseRealTimeQuotes',
  VIEW_POWERHOUSE_O_P_I = 'ViewPowerhouseOPI',
  VIEW_POWERHOUSE_O_P_P = 'ViewPowerhouseOPP',
  VIEW_POWERHOUSE_PRO_COMMON = 'ViewPowerhouseProCommon',
  VIEW_POWERHOUSE_PRO_STRATEGIES = 'ViewPowerhouseProStrategies',
  VIEW_USER_ACTIVITIES = 'ViewUserActivities',
  WORK_WITH_POWERHOUSE_PRO_CORE = 'WorkWithPowerhouseProCore',
  WORK_WITH_WATCH_LISTS = 'WorkWithWatchLists',
  IS_LOGGED_AS = 'IsLoggedAs',
  ALLOW_SHARE_BY_EMAIL = 'AllowShareByEmail',
  ALLOW_SHARE_BY_EMAIL_CLIENT = 'AllowShareByEmailClient',
  ALLOW_SHARE_BY_TWITTER = 'AllowShareByTwitter',
  ALLOW_SHARE_TO_STOCK_TWITS = 'AllowShareToStockTwits',
  ALLOW_COPY_LINK = 'AllowCopyLink',
  CAN_DOWNLOAD_ALERTS = 'CanDownloadAlerts',
  VIEW_GLOBAL_PORTFOLIOS = 'ViewGlobalPortfolios',
  MANAGE_GLOBAL_PORTFOLIOS = 'ManageGlobalPortfolios',
  LINK_PORTFOLIO = 'LinkPortfolio',
  MANUAL_PORTFOLIO = 'ManualPortfolio',
  VIEW_TRADE_IDEAS = 'ViewTradeIdeas',
  ALLOW_SYMBOL_SEARCH = 'AllowSymbolSearch',
  ALLOW_EDIT_USER_WATCHLIST = 'AllowEditUserWatchlist',
  T_D_AMERITRADE_ACCOUNT_LINKING = 'TDAmeritradeAccountLinking',
  ALLOW_USER_WATCHLIST = 'AllowUserWatchlist',
}

export enum BillingModel {
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
}

export enum SubscriptionStatus {
  ACTIVE_TRIAL = 'ActiveTrial',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  NO_SUBSCRIPTIONS = 'NoSubscriptions',
  NO_APPLIED_SUBSCRIPTIONS = 'NoAppliedSubscriptions',
  EXPIRED_TRIAL = 'ExpiredTrial',
  EXPIRED_EXTENDED_TRIAL = 'ExpiredExtendedTrial',
  CANCELED = 'Canceled',
  SUSPENDED = 'Suspended',
  TERMINATED = 'Terminated',
  CANCELED_NOT_EXPIRED = 'CanceledNotExpired',
  SUSPENDED_NOT_EXPIRED = 'SuspendedNotExpired',
}

export enum PriceCalculationMethod {
  BID_ASK = 'BidAsk',
  MID = 'Mid',
}

export enum WhatIfDefaultPriceType {
  CURRENT_PRICE = 'CurrentPrice',
  SD = 'Sd',
}

export enum AlertType {
  ALERT = 'Alert',
  NOTIFICATION = 'Notification',
}

export enum RiskRewardType {
  UNLIMITED = 'Unlimited',
}

export enum CombinationType {
  PORTFOLIO_CURRENT = 'PortfolioCurrent',
  PORTFOLIO_ADJUSTMENT = 'PortfolioAdjustment',
  PORTFOLIO_RESULTING = 'PortfolioResulting',
  TRADE = 'Trade',
  INCOME = 'Income',
}

export enum AlertAdjustmentAction {
  COVERED_CALL = 'CoveredCall',
  CLOSE_POSITION = 'ClosePosition',
  ADD_LEG = 'AddLeg',
  CLOSE_LEG = 'CloseLeg',
  ROLL_POSITION = 'RollPosition',
  NOT_SPECIFIED = 'NotSpecified', // ADDED
}

export enum AdjustmentAction {
  CLOSE_POSITION = 'CLOSE_POSITION',
  ADD_LEG = 'ADD_LEG',
  CLOSE_LEG = 'CLOSE_LEG',
  ADD_CLOSE_LEG = 'AddCloseLeg',
  ROLL_POSITION = 'RollPosition',
}

export enum PorfolioAction {
  COVERED_CALL = 'Covered call',
  CLOSE_POSITION = 'Close position',
  ADD_LEG = 'Add leg',
  CLOSE_LEG = 'Close leg',
  ROLL_POSITION = 'Roll position',
  NOT_SPECIFIED = 'Not specified',
  ADD_CLOSE_LEG = 'Add/close leg',
  STRATEGY = 'Strategy',
}

export enum AdjustmentActionName {
  ClosePosition = 'Close Position',
  AddLeg = 'Add Leg',
  CloseLeg = 'Close Leg',
  AddCloseLeg = 'Add/Close Leg',
  RollPosition = 'Roll Position',
  NotSpecified = 'Not Specified', // ADDED
}

export enum RollPositionWhen {
  OPTIMAL_ONLY = 'OptimalOnly',
  OPTIMAL_AND_ALTERNATIVE = 'OptimalAndAlternative',
}

export enum RollPositionType {
  ROLLING_OUT = 'Roll Out',
  ROLLING_UP_OUT = 'Roll Up And Out',
  ROLLING_DOWN_OUT = 'Roll Down And Out',
}

export enum WatchListsTypes {
  NONE = 'None',
  GLOBAL_B2B = 'GlobalB2B',
  GLOBAL_B2C = 'GlobalB2C',
}

export enum TimeFrame {
  SHORT = 'Short',
  MEDIUM = 'Medium',
  LONG = 'Long',
}

export enum Aggressiveness {
  CONSERVATIVE = 'Conservative',
  OPTIMAL = 'Optimal',
  AGGRESSIVE = 'Aggressive',
}

export enum ProcessingStatus {
  NOT_SCPECIFIED = 'NotSpecified',
  NOT_PROCESSED = 'NotProcessed',
  PENDING = 'Pending',
  PROCESSED = 'Processed',
}

export enum StrategyConstructor {
  LONG_CALL_VERTICAL = 'Long Call Vertical',
  LONG_PUT_VERTICAL = 'Long Put Vertical',
}

export enum WatchListsOperationTypes {
  NEW = 'New',
  EDIT = 'Edit',
  DELETE = 'Delete',
  NONE = 'None',
  NEWGLOBAL = 'New Global',
}

export enum PromoCodes {
  Sharing = 'SHARING',
}

export enum tradeSimulatorOperationType {
  PLSimulator = 'PLSimulator',
  TradingRangeSimulator = 'TradingRangeSimulator',
  PLCalculator = 'PLCalculator',
  PlainEnglish = 'PlainEnglish',
  Modify = 'Modify',
  RiskInvestmentCalculator = 'RiskInvestmentCalculator',
  IncomeSettings = 'IncomeSettings',
  OptionsGrid = 'OptionsGrid',
  ShowRisksandCheckilst = 'ShowRisksandCheckilst',
  IncomeProfitAndLossTarget = 'IncomeProfitAndLossTarget',
  ExplainStrategyandGreeks = 'ExplainStrategyandGreeks',
}

export enum ClosePositionType {
  FULL_CLOSE = 'FullClose',
  PARTIAL_CLOSE = 'PartialClose',
}

export enum ActionTypes {
  Change_Price_Bid_Ask = 'ChangePriceBidAsk',
  Change_Price_Mid = 'ChangePriceMid',
  Reset = 'Reset',
  ShrinkWidth = 'ShrinkWidth',
  ExpandWidth = 'ExpandWidth',
  ShrinkWingspan = 'ShrinkWingspan',
  ExpandWingspan = 'ExpandWingspan',
  Recommendation = 'Recommendation',
}

export enum AmountType {
  INVESTMENT = 'investment',
  RISK = 'risk',
}

export enum RecommendedPrice {
  BidPrice = 'bid-price',
  MidPrice = 'mid-price',
  AskPrice = 'ask-price',
}

export enum Exchange {
  // IND refers to Indexed Exchange code which itself a product.
  XSTO = 'XSTO',
  INDXSTO = 'INDXSTO',
  XCSE = 'XCSE',
  INDXCSE = 'INDXCSE',
  XHEL = 'XHEL',
  INDXHEL = 'INDXHEL',
  US = 'US',
  XTSE = 'XTSE',
}

export enum MarketRegion {
  Nordic = 'NO',
  US = 'US',
  TMX = 'CA',
}

export enum CurrencyCode {
  Stockholm = 'SEK',
  Copenhagen = 'DKK',
  Helsinki = 'EUR',
  Canada = 'CAD',
  US = 'USD',
}

export enum StrategyName {
  PutVertical = 'Put Vertical',
}

// For user activity events
export enum EventType {
  Click = 'click',
  Submit = 'submit',
  SignOut = 'signOut',
  SignIn = 'signIn',
}

export enum StrategyType {
  CoveredCall = 'Covered Call',
  Call = 'Call',
  Put = 'Put',
}

export enum MarketCapValues {
  smallCap = 'smallCap',
  mediumCap = 'mediumCap',
  largeCap = 'largeCap',
  megaCap = 'megaCap',
}

export enum IVRankValues {
  LessThan50 = 'Less than 50',
  GreaterEqualTo50 = 'Greater than or equal to 50',
}

export enum LiquidityRatingValues {
  NL = 'Not Liquid',
  SL = 'Somewhat Liquid',
  VL = 'Very Liquid',
}

export enum PortfolioType {
  UserUpload = 'UserUpload',
  Global = 'Global',
  Others = 'Others',
  TDAmeritrade = 'TDAmeritrade',
}

export enum AccountTypeStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
}

export type ideaNames = 'technicalIdea' | 'coveredCall' | 'creditSpread' | 'shortPut';

export interface IdeaType {
  name: ideaNames;
  value: string;
  to: string;
}

export const types = [
  {
    name: 'technicalIdea',
    value: 'Technical Ideas',
    to: '/idea/technical-idea',
  },
  {
    name: 'coveredCall',
    value: 'Covered Calls',
    to: '/idea/covered-call',
  },
  {
    name: 'creditSpread',
    value: 'Credit Spreads',
    to: '/idea/credit-spread',
  },
  {
    name: 'shortPut',
    value: 'Short Puts',
    to: '/idea/short-put',
  },
] as IdeaType[];
