import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { EventType, Permissions } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { Combination } from '@op/shared/src/models/how/combination';
import SingleTrade from '@op/shared/src/models/how/single-trade';
import { PaymentsService } from '@op/shared/src/services';
import {
  accountState,
  customizationState,
  selectedSymbolState,
  themeState,
  whatIfSimulatorState,
} from '@op/shared/src/states';
import * as htmlToImage from 'html-to-image';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import linkWhite from '../images/link-white.png';
import Link from '../images/link.png';
import MailWhite from '../images/mail-white.png';
import Mail from '../images/mail.png';
import Send from '../images/send.png';
import ST from '../images/st.png';
import sendWhite from '../images/telegram-white.png';
import Twitter from '../images/twitter.png';
import { EmailClientShare, LinkShare, StockShare, TelegramShare, TwitterShare } from '../single-trade';
import { GuideItem } from '../side-menu';

const pointer = {
  cursor: 'pointer',
};
const singleShareIcon = {
  width: 20,
  height: 20,
};
export interface ISingleShareWidgetProps {
  data: SingleTrade;
  comb: Combination;
}

export const SingleShareWidget: React.FC<ISingleShareWidgetProps> = ({ data, comb }: ISingleShareWidgetProps) => {
  const theme = useRecoilState(themeState);
  const account = useRecoilValue(accountState);
  const activeTheme = theme[0];
  const [telgramModalOpen, setTelegramModalOpen] = React.useState(false);
  const [twitterModalOpen, setTwitterModalOpen] = React.useState(false);
  const [mailModalOpen, setMailModalOpen] = React.useState(false);
  const [stockModalOpen, setStockModalOpen] = React.useState(false);
  const [linkModalOpen, setLinkModalOpen] = React.useState(false);
  const [screenshotsrc, setScreenshotSrc] = React.useState('');
  const [screenshotid, setScreenshotid] = React.useState<any>('');
  const whatIfSimulator = useRecoilValue(whatIfSimulatorState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const currentTheme = useTheme();
  //const customization = useRecoilValue(customizationState);

  // if (!whatIfSimulator || !whatIfSimulator.whatIfDate || !customization || customization.isEmbeddingPlatform) {
  //   return null;
  // }

  let node: HTMLElement;
  const getNode = () => {
    if (comb.isTrade) {
      node = document.getElementById('screenshot') as HTMLElement;
    }
    if (comb.isIncome) {
      node = document.getElementById('income_screenshot') as HTMLElement;
    }
  };
  getNode();

  const quote = data.quote;
  const { symbol } = quote;
  const title = data.combinationTitle;
  const value = title.substring(0, title.indexOf('@'));
  const sentimentType: any = data.syrahSentiments;
  const sentimentValue = sentimentType.charAt(0).toUpperCase() + sentimentType.slice(1);
  const textareaTiltle = `${sentimentValue} $${symbol} Trade: ${title}`;
  const sentiment = data.syrahSentiments;
  if (!account || !account.securityModel) {
    return <></>;
  }

  const singleSharePermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_BY_EMAIL);
  const emailSharePermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_BY_EMAIL_CLIENT);
  const shareByTwitterPermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_BY_TWITTER);
  const shareByStockTwistPermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_TO_STOCK_TWITS);
  const shareByLinkPermission = account.securityModel.hasPermission(Permissions.ALLOW_COPY_LINK);

  const getScreenshot = async () => {
    // Temporary fix to get the node, if not exists
    if (!node) {
      getNode();
    }
    htmlToImage
      .toPng(node, {
        style: { backgroundColor: currentTheme.palette.background.paper },
      })
      .then(async function (dataUrl) {
        const img = new Image();
        img.src = dataUrl;
        setScreenshotSrc(dataUrl);
        if (comb && comb.positions && comb.positions.length > 0) {
          if (!whatIfSimulator.whatIfDate) {
            return null;
          }
          let legData: any[] = [];
          comb.positions.forEach((itemPosition: any) => {
            let position = {
              legType: itemPosition.legType,
              strikePrice: itemPosition.strikePrice,
              costBasis: itemPosition.costBasis,
              quantity: itemPosition.quantity,
              expiry: itemPosition.expiry,
            };
            legData.push(position);
          });
          const whatIfDate = whatIfSimulator.whatIfDate;
          const targetDate = new Date(
            Date.UTC(whatIfDate.getFullYear(), whatIfDate.getMonth(), whatIfDate.getDate(), 0, 0, 0),
          );
          const sendImageContents = {
            sharedTrade: {
              fullName: value,
              symbol: symbol,
              legs: legData,
              costBasis: comb.midPricedCost(),
              targetDate: targetDate.toISOString(),
              targetPrice: whatIfSimulator.whatIfSPrice,
              priceCalculationMethod: comb.priceCalculationMethod,
              combinationType: comb.combinationType,
            },
            screenshot: {
              content: dataUrl.substring(dataUrl.indexOf(',') + 1),
              mimeType: 'image/png',
              name: `Share: ${value}`,
              description: textareaTiltle,
            },
          };
          const response = await PaymentsService.instance.saveImage(sendImageContents);
          setScreenshotid(response.data);
        }
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const renderSingleShareWidget = () => {
    //showShareView &&
    if (
      !singleSharePermission &&
      !emailSharePermission &&
      !shareByTwitterPermission &&
      !shareByStockTwistPermission &&
      !shareByLinkPermission
    ) {
      return <Box p={1} style={{ margin: 0 }}></Box>;
    }
    return (
      <Paper elevation={1} sx={{ p: 1, borderRadius: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" textAlign="center">
          <GuideItem selector=".shareTradeButton_helpPinPlaceholder" />
          <Grid item>
            <Typography variant="body1" lineHeight={1.2}>
              Share via
            </Typography>
          </Grid>
          <Grid item>
            {singleSharePermission && (
              <img
                alt="send"
                onClick={async () => {
                  await getScreenshot();
                  logActivity('BUTTON', 'shareViaEmail');
                  setTelegramModalOpen(true);
                }}
                style={{ ...pointer, ...singleShareIcon }}
                src={activeTheme === 'light' ? Send : sendWhite}
              />
            )}
          </Grid>
          <Grid item>
            {emailSharePermission && (
              <img
                alt="Mail"
                style={{ ...pointer, ...singleShareIcon }}
                onClick={async () => {
                  await getScreenshot();
                  logActivity('BUTTON', 'shareViaClientEmail');
                  setMailModalOpen(true);
                }}
                src={activeTheme === 'light' ? Mail : MailWhite}
              />
            )}
          </Grid>
          <Grid item>
            {shareByTwitterPermission && (
              <img
                alt="Twitter"
                style={{ ...pointer, ...singleShareIcon }}
                onClick={async () => {
                  await getScreenshot();
                  logActivity('BUTTON', 'shareViaTwitter');
                  setTwitterModalOpen(true);
                }}
                src={Twitter}
              />
            )}
          </Grid>
          <Grid item>
            {shareByStockTwistPermission && (
              <img
                alt="stock twits"
                onClick={async () => {
                  await getScreenshot();
                  logActivity('BUTTON', 'shareViaStocktwits');
                  setStockModalOpen(true);
                }}
                style={{ ...pointer, ...singleShareIcon }}
                src={ST}
              />
            )}
          </Grid>
          <Grid item>
            {shareByLinkPermission && (
              <img
                style={{ ...pointer, ...singleShareIcon, marginBottom: 2 }}
                alt="Link"
                onClick={async () => {
                  await getScreenshot();
                  logActivity('BUTTON', 'copyShareToClipboard');
                  setLinkModalOpen(true);
                }}
                src={activeTheme === 'light' ? Link : linkWhite}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <>
      {twitterModalOpen && (
        <TwitterShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          onHandleClose={() => setTwitterModalOpen(false)}
        />
      )}
      {mailModalOpen && (
        <EmailClientShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          screenshotid={screenshotid}
          sentiment={sentiment}
          onHandleClose={() => setMailModalOpen(false)}
        />
      )}
      {stockModalOpen && (
        <StockShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          onHandleClose={() => setStockModalOpen(false)}
        />
      )}
      {linkModalOpen && (
        <LinkShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          screenshotid={screenshotid}
          onHandleClose={() => setLinkModalOpen(false)}
        />
      )}
      {telgramModalOpen && (
        <TelegramShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          onHandleClose={() => setTelegramModalOpen(false)}
          screenshotId={screenshotid}
        />
      )}
      <>{renderSingleShareWidget()}</>
    </>
  );
};
