import { Grid, styled } from '@mui/material';

export const OptionsPlayStrategyWidgetGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    height: 145,
  },
  [theme.breakpoints.up('sm')]: {
    height: 200,
  },
  [theme.breakpoints.up('md')]: {
    height: 200,
  },
  [theme.breakpoints.up('lg')]: {
    height: 200,
  },
  [theme.breakpoints.up('xl')]: {
    height: 200,
  },
}));
